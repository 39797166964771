import firebase from "firebase/compat/app";
import { getFunctions } from "firebase/functions";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyARElYdtcqB3dbi-8aBCkcy2d5XY6YTimI",
  authDomain: "poupas-9e426.firebaseapp.com",
  projectId: "poupas-9e426",
  storageBucket: "poupas-9e426.appspot.com",
  messagingSenderId: "150574896787",
  appId: "1:150574896787:web:2b20588e583f9ed2331f66",
};

const app = firebase.initializeApp(firebaseConfig);
firebase.firestore();
export const functions = getFunctions(app);
export default firebase;
