import { DatePicker } from "antd";
import React from "react";
import { Form } from "../../atoms";
const format = "DD/MM/YYYY";
interface IInputDateRangeFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  minuteStep?: number;
}

export const InputDateRangeField: React.FC<IInputDateRangeFieldProps> = ({
  label,
  rules,
  name,
  placeholder,
  style,
  className,
  initialValue,
  bordered = true,
  minuteStep = 10,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
  >
    <DatePicker.RangePicker
      bordered={bordered}
      style={style}
      format={format}
      minuteStep={minuteStep}
    />
  </Form.Item>
);
