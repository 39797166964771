import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_AMENITY2092 } from "./firebaseConstants";

export function fetchAmenity2092FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_AMENITY2092);
  return collectionWithArgs(ref, args || []);
}
export function listenToAmenity2092FromFirestore(id: string) {
  return db.collection(COLLECTION_AMENITY2092).doc(id);
}
