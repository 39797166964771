import React from "react";
import { Form, Input } from "../../atoms";

interface IInputTextAreaFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  rows?: number;
  showCount?: boolean;
  maxLength?: number;
}

export const InputTextAreaField: React.FC<IInputTextAreaFieldProps> = ({
  label,
  rules,
  name,
  placeholder,
  style,
  className,
  initialValue,
  bordered = true,
  rows = 3,
  maxLength = 2000,
  showCount,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
  >
    <Input.TextArea
      placeholder={placeholder}
      bordered={bordered}
      rows={rows}
      showCount={showCount}
      maxLength={maxLength}
    />
  </Form.Item>
);
