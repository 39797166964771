import React from "react";
import { useParams } from "react-router-dom";
import { PackageBooking } from "../../App/components/organisms";

const PackageBookingPage: React.FC = () => {
  const params = useParams();
  const bookingId = params.bookingId || "";
  return (
    <div>
      <PackageBooking bookingId={bookingId} />
    </div>
  );
};

export default PackageBookingPage;
