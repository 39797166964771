import { values } from "lodash";
import { IMeal } from "../common/typings";

interface IMealTypesMap {
  [key: string]: IMeal;
}
export const mealTypes: IMealTypesMap = {
  NO_MEAL: {
    value: "NO_MEAL",
    label: "Χωρίς Γεύμα",
    include: "Χωρίς Γεύμα",
  },
  BREAKFAST: {
    value: "BREAKFAST",
    label: "πρόγευμα",
    include: "Περιλαμβάνεται  πρόγευμα",
  },
  HALF_BOARD: {
    value: "HALF_BOARD",
    label: "Ημιδιατροφή",
    include: "Περιλαμβάνεται Πρόγευμα και Βραδινό",
  },
  FULL_BOARD: {
    value: "FULL_BOARD",
    label: "Πλήρες Διατροφή",
    include: "Περιλαμβάνεται Πρόγευμα, Μεσημεριανό και Βραδινό",
  },
  ALL_INCLUSIVE: {
    value: "ALL_INCLUSIVE",
    label: "All Inclusive Διατροφή",
    include: "Περιλαμβάνεται All Inclusive Διατροφή",
  },
};

export const mealTypesOptions = values(mealTypes);
