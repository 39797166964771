import { ICategory } from "../../../common/typings";
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  FETCH_CATEGORIES,
  CLEAR_COMMENTS,
  LISTEN_TO_SELECTED_CATEGORY,
  CLEAR_CATEGORIES,
  SET_FILTER,
  SET_START_DATE,
  RETAIN_STATE,
  CLEAR_SELECTED_CATEGORY,
} from "./categoryConstants";

const initialState = {
  categories: [],
  moreCategories: true,
  selectedCategory: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function categoryReducer(
  state = initialState,
  { type, payload }: any,
) {
  switch (type) {
    case CREATE_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, payload],
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.filter((evt: ICategory) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.filter((evt: ICategory) => evt.id !== payload),
        ],
      };
    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: payload,
        moreCategories: payload.moreCategories,
        lastVisible: payload.lastVisible,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };
    case CLEAR_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: null,
      };
    case CLEAR_CATEGORIES:
      return {
        ...state,
        categories: [],
        moreCategories: true,
        lastVisible: null,
      };
    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        moreCategories: true,
        filter: payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreCategories: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
