import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
export interface IItemNotFoundProps {
  redirectTo: string;
  redirectBtnText: string;
  itemType?: string;
  itemId: string;
  redirectAction?: () => void;
  customMessage?: string;
}

export const ItemNotFound: React.FC<IItemNotFoundProps> = ({
  redirectTo,
  redirectBtnText,
  itemType,
  itemId,
  redirectAction,
  customMessage,
}) => {
  const history = useNavigate();
  const message = (
    <div>
      <h3>{`Ooops! ${itemType || "Item"} not Found.`}</h3>
      <p style={{ fontSize: "14px" }}>
        {`Could not find ${itemType || "Item"} with ID: ${itemId}`}
        <br />
        {customMessage ||
          "Most probably the ID is incorrect, has been deleted or you do not have the required access permission to view it."}
      </p>
    </div>
  );
  const redirectBtnMessage = redirectBtnText || "Go Back";
  const redirectButton = (redirectTo || redirectAction) && (
    <Button
      type="primary"
      icon={"arrow-left"}
      onClick={() => {
        if (redirectTo) history(redirectTo);
        if (redirectAction) redirectAction();
      }}
    >
      {redirectBtnMessage}
    </Button>
  );

  return <Result title={message} extra={redirectButton} status="error" />;
};

export default ItemNotFound;
