/* eslint-disable no-console */
import { CloseCircleOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React from "react";
import { getYearMonthsOptions } from "../../../common/util/util";
import { Collapse, Form } from "../../atoms";
import { CheckboxGroup } from "../../molecules";
import { HotelFilterWrapper } from "./Hotel.style";
const { Panel } = Collapse;
const searchParams = new URLSearchParams({
  from: "all",
  to: "all",
  when: "all",
  category: "all",
  price: "0_5000",
});
const handelUrlChange = (
  name: string,
  value: CheckboxValueType[],
  url: string,
) => {
  searchParams.set(name, `${value.join("_")}`);
  window.history.replaceState({}, "", `/${url}/${searchParams.toString()}`);
};

const departureCitiesOptions = [
  { label: "Λάρνακα", value: "1" },
  { label: "Λεμεσός", value: "2" },
  { label: "Πρωταράς", value: "3" },
  { label: "Αγία Νάπα", value: "4" },
  { label: "Πάφος", value: "5" },
];
export interface IProps {
  isTabletOrMobile: boolean;
  showFilter: () => void;
  params: any;
  url: string;
}
export const HotelsFilters: React.FC<IProps> = ({
  isTabletOrMobile,
  showFilter,
  url,
}) => {
  const [departureCities] = React.useState<CheckboxValueType[]>([]);
  const [departureMonths] = React.useState<CheckboxValueType[]>([]);
  const [form] = Form.useForm();

  const handelDepartureCitiesOnChange = (value: CheckboxValueType[]) => {
    handelUrlChange("from", value, url);
  };
  const handelDepartureMonthsOnChange = (value: CheckboxValueType[]) => {
    handelUrlChange("when", value, url);
  };

  React.useEffect(() => form.resetFields);
  return (
    <HotelFilterWrapper>
      <div className="package-filter-header">
        <span>Διαμορφώστε την αναζήτηση σας</span>
        {isTabletOrMobile && <CloseCircleOutlined onClick={showFilter} />}
      </div>
      <div className="package-filter-body">
        <Form form={form} layout="vertical" className="package-filter-form">
          <Collapse
            defaultActiveKey={["1", "5"]}
            expandIconPosition="start"
            bordered={false}
            className="package-filter-collapse"
          >
            <Panel header="Πόλεις" key="1">
              <Form.Item name="cities">
                <CheckboxGroup
                  plainOptions={departureCitiesOptions}
                  defaultCheckedList={departureCities}
                  onChange={handelDepartureCitiesOnChange}
                  checkAllName="Όλες οι Πόλεις"
                />
              </Form.Item>
            </Panel>
            <Panel header="Μήνας" key="3">
              <Form.Item name="month">
                <CheckboxGroup
                  plainOptions={getYearMonthsOptions()}
                  defaultCheckedList={departureMonths}
                  onChange={handelDepartureMonthsOnChange}
                  checkAllName="Όλους"
                />
              </Form.Item>
            </Panel>
            <Panel header="Κατηγορίες" key="4">
              <Form.Item name="categories"></Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </HotelFilterWrapper>
  );
};
