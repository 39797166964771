import { DatePicker } from "antd";
import React from "react";
import { Form } from "../../atoms";
const format = "DD/MM/YYYY";
interface IInputDateFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  minuteStep?: number;
  disabledDate?: (current: any) => boolean;
  noStyle?: boolean;
  disabled?: boolean;
}

export const InputDateField: React.FC<IInputDateFieldProps> = ({
  label,
  rules,
  name,
  placeholder,
  style,
  className,
  initialValue,
  bordered = true,
  minuteStep = 10,
  disabledDate,
  noStyle,
  disabled,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
    noStyle={noStyle}
  >
    <DatePicker
      bordered={bordered}
      style={style}
      format={format}
      minuteStep={minuteStep}
      disabled={disabled}
      disabledDate={disabledDate}
    />
  </Form.Item>
);
