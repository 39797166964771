import React from "react";
import { Button, Tooltip } from "../../../atoms";
import { PeriodBoxWrapper } from "../Hotel.style";
export interface IPriceBoxProps {
  title: string;
  descriptions: string;
  duration: number;
  action: () => void;
}
export const PeriodBox: React.FC<IPriceBoxProps> = ({
  title,
  descriptions,
  duration,
  action,
}) => (
  <PeriodBoxWrapper onClick={action}>
    <div className="PeriodBox-title">
      <Tooltip title={title}>{descriptions}</Tooltip>
    </div>
    <div className="PeriodBox-body">
      <div className="PeriodBox-des">Ελάχιστη Διαμονή </div>
      <span className="PeriodBox-mine">
        {duration}
        {duration === 1 ? " Βράδυ" : " Βράδια"}
      </span>
    </div>
    <Button className="PeriodBox-select" type="primary" onClick={action}>
      Επιλογή
    </Button>
  </PeriodBoxWrapper>
);
