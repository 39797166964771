import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_HOTEL28355,
  COLLECTION_ROOM40231,
  COLLECTION_ROOMIMAGE27052,
} from "./firebaseConstants";

export function fetchRoomImage27052FromFirestore(
  hotelId: string,
  roomId: string,
  args?: IQueryConfigArgs[],
) {
  const ref = db
    .collection(COLLECTION_HOTEL28355)
    .doc(hotelId)
    .collection(COLLECTION_ROOM40231)
    .doc(roomId)
    .collection(COLLECTION_ROOMIMAGE27052);
  return collectionWithArgs(ref, args || []);
}

export function listenToRoomImage27052FromFirestore(
  hotelId: string,
  roomId: string,
  id: string,
) {
  return db
    .collection(COLLECTION_HOTEL28355)
    .doc(hotelId)
    .collection(COLLECTION_ROOM40231)
    .doc(roomId)
    .collection(COLLECTION_ROOMIMAGE27052)
    .doc(id);
}
