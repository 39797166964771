import { Tag, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import { HotelRoomInfoWrapper } from "../Hotel.style";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import { IRoom40231 } from "../../../../common/types";
import { ImageGallery } from "../..";
import { useFirestoreCollection } from "../../../../hooks/useFirestoreCollection";
import {
  fetchRoomImage27052FromFirestore,
  listenToRoom40231FromFirestore,
} from "../../../../firestore/firestoreService";
interface IProps {
  hotelId: string;
  roomId: string;
  onRequest: boolean;
  roomAvailable: number;
}
export const HotelRoomInfo: React.FC<IProps> = ({
  hotelId,
  roomId,
  onRequest,
  roomAvailable,
}) => {
  const dispatch = useDispatch();
  const [images, setImages] = React.useState([]);
  const { amenity2092Map } = useSelector((state: any) => state.amenity2092);
  const [room, setRoom] = React.useState<IRoom40231>();
  userFirestoreDoc({
    query: () => listenToRoom40231FromFirestore(hotelId, roomId || ""),
    data: setRoom,
    shouldExecute: !!roomId && !!hotelId,
    deps: [dispatch, roomId],
    local: true,
  });
  useFirestoreCollection({
    query: () => fetchRoomImage27052FromFirestore(hotelId, roomId),
    data: setImages,
    deps: [dispatch, hotelId, roomId],
    local: true,
    shouldExecute: !!hotelId && !!roomId,
  });
  return (
    <HotelRoomInfoWrapper>
      <div className="HotelRoomInfo-details">
        <ImageGallery
          images={images || []}
          showThumbnails={false}
          showPlayButton={false}
        />
        <span className="HotelRoomInfo-title">{room?.name || ""}</span>
        <span className="HotelRoomInfo-squareMeters">
          {onRequest && (
            <Tooltip title="Δυστυχώς το δωμάτιο αυτό  φαίνεται πως έχει εξαντληθεί για τη συγκεκριμένη περίοδο. Μπορείτε παρόλα αυτά να ολοκληρώσετε τη κράτηση χωρίς απολύτως καμία χρέωση και θα λάβετε απάντηση για διαθεσιμότητα εντός 24 εργάσιμων ωρών. Εναλλακτικά μπορείτε να επιλέξετε νέες ημερομηνίες.">
              <span style={{ color: "red" }}>On Request</span>
            </Tooltip>
          )}
          {!onRequest && roomAvailable <= 5 && (
            <span style={{ color: "red" }}>
              Τελευταία Δωμάτια {roomAvailable}
            </span>
          )}
        </span>
        <div className="HotelRoomInfo-amenities">
          {room?.amenitiesIds.map((amenityId) => (
            <Tag key={amenityId} icon={<CheckOutlined />}>
              {amenity2092Map[amenityId].name}
            </Tag>
          ))}
        </div>
      </div>
    </HotelRoomInfoWrapper>
  );
};
