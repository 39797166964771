import styled from "styled-components";
export const BookingWrapper = styled.div`
  .booking {
    &-container {
      padding: 20px;
      margin: 20px;
      background-color: #fff;
    }
    &-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    &-titleRoom {
      border-bottom: 1px solid rgb(238, 238, 238);
      background-color: #eef5f7;
      line-height: 36px;
      padding-left: 20px;
      margin: -20px -20px 20px;
      font-weight: 500;
      font-size: 13px;
    }
    &-policy {
      color: #4caf50;
    }
    &-name {
      font-size: 16px;
      color: #005fc3;
      font-weight: 900;
      font-family: "avenir";
      &-meal {
        color: #0eb381;
        font-weight: normal;
      }
    }
    &-location {
      color: #313e55;
      font-size: 13px;
    }
    &-btn {
      background-color: #1d3bf8;
      height: 64px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-family: "Avenir";
      font-weight: 900;
    }
    &-more-details {
      color: #005fc3;
      font-size: 12px;
      cursor: pointer;
    }
  }
`;
