/* eslint-disable no-console */
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Select } from "../../atoms";
import { LuggageSelectorsWrapper } from "./PackageViewFrontEnd.style";

export interface IPackageLuggageProps {
  passengers: number;
  price20KGLuggage: number;
  price10KGLuggage: number;
  setNumberOf10KGLuggage: (value: number) => void;
  setNumberOf20KGLuggage: (value: number) => void;
  isMobile: boolean;
}

export const PackageLuggage: React.FC<IPackageLuggageProps> = ({
  passengers,
  price20KGLuggage,
  price10KGLuggage,
  setNumberOf10KGLuggage,
  setNumberOf20KGLuggage,
  isMobile,
}) => {
  const [totalPrice10KGLuggage, setTotalPrice10KGLuggage] = React.useState(0);
  const [totalPrice20KGLuggage, setTotalPrice20KGLuggage] = React.useState(0);
  const { formatMessage } = useIntl();

  const handleChangeNumber10KGLuggage = (value: string) => {
    setNumberOf10KGLuggage(Number(value));
    setTotalPrice10KGLuggage(Number(value) * Number(price10KGLuggage));
  };
  const handleChangeNumber20KGLuggage = (value: string) => {
    setNumberOf20KGLuggage(Number(value));
    setTotalPrice20KGLuggage(Number(value) * Number(price20KGLuggage));
  };
  return (
    <LuggageSelectorsWrapper>
      <div className="luggage-selectors-title">
        <FormattedMessage
          description="Add Luggage to your booking"
          defaultMessage="Θέλετε να προσθέσετε αποσκευές στη Κράτηση σας;"
          id="EnJmYu"
        />
      </div>
      <div className="luggage-selectors-list">
        <Form name="horizontal_login" layout="vertical">
          {price10KGLuggage > 0 && (
            <div className="luggage-selectors-item">
              <Form.Item
                name="numberOf10KGLuggage"
                label={formatMessage({
                  description: "Number 10KG Luggage",
                  defaultMessage:
                    "Χειραποσκευή καμπίνας (55x40x20 εκ) μέχρι 10 κιλά",
                  id: "uqDlgq",
                })}
                className="luggage-selectors-item-selector"
                initialValue={0}
              >
                <Select
                  placeholder={formatMessage({
                    description: "Number 20KG Luggage",
                    defaultMessage:
                      "Χειραποσκευή καμπίνας (55x40x20 εκ) μέχρι 10 κιλά",
                    id: "/AqsGP",
                  })}
                  style={{ width: 200 }}
                  onChange={handleChangeNumber10KGLuggage}
                  options={[
                    {
                      label: `Καμία`,
                      value: 0,
                    },
                    ...Array(passengers)
                      .fill(passengers)
                      .map((v, i) => ({
                        label: `${i + 1} Χειραποσκευή 10 κιλών`,
                        value: i + 1,
                      })),
                  ]}
                ></Select>
              </Form.Item>
              <div className="luggage-selectors-item-totalPrice">
                € {totalPrice10KGLuggage}
              </div>
            </div>
          )}
          {price20KGLuggage > 0 && (
            <div className="luggage-selectors-item">
              <Form.Item
                name="numberOf20KGLuggage"
                label={formatMessage({
                  description: "Number 20KG Luggage",
                  defaultMessage: "Παραδοτέα αποσκευή μέχρι 20 κιλά",
                  id: "0gPzra",
                })}
                className="luggage-selectors-item-selector"
                initialValue={0}
              >
                <Select
                  placeholder={formatMessage({
                    description: "Number 20KG Luggage",
                    defaultMessage: "Παραδοτέα αποσκευή μέχρι 20 κιλά",
                    id: "0gPzra",
                  })}
                  onChange={handleChangeNumber20KGLuggage}
                  style={{ width: 200 }}
                  options={[
                    {
                      label: `Καμία`,
                      value: 0,
                    },
                    ...Array(passengers)
                      .fill(passengers)
                      .map((v, i) => ({
                        label: `${i + 1} Αποσκευή 20 κιλών`,
                        value: i + 1,
                      })),
                  ]}
                ></Select>
              </Form.Item>
              <div className="luggage-selectors-item-totalPrice">
                € {totalPrice20KGLuggage}
              </div>
            </div>
          )}
          {/* <div className="luggage-selectors-totalPrice">
            <span>Total: </span> €{" "}
            {totalPrice20KGLuggage + totalPrice10KGLuggage}
          </div> */}
        </Form>{" "}
      </div>
    </LuggageSelectorsWrapper>
  );
};
