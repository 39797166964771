import React from "react";
import { useDispatch } from "react-redux";
import { Space } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { orderBy } from "lodash";
import { HotelInfo, ImageGallery } from "..";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import {
  fetchExclusiveHotel64774FromFirestore,
  fetchHotelImage94307FromFirestore,
} from "../../../firestore/firestoreService";
import { IExclusiveHotel64774, IHotel28355 } from "../../../common/types";
import { formatReadableAddress } from "../../../common/util/util";
import { HotelItemWrapper } from "./Hotel.style";
import { PeriodBox } from "./components/PeriodBox";

interface IProps {
  hotelId: string;
  hotel: IHotel28355;
  isTabletOrMobile: boolean;
}
const partnerId = process.env.REACT_APP_SITE_ID || "";
export const HotelItem: React.FC<IProps> = ({
  hotelId,
  hotel,
  isTabletOrMobile,
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [images, setImages] = React.useState([]);
  const [exclusiveOffers, setExclusiveOffers] = React.useState([]);
  useFirestoreCollection({
    query: () => fetchHotelImage94307FromFirestore(hotelId),
    data: setImages,
    deps: [dispatch, hotelId],
    local: true,
    shouldExecute: !!hotelId,
  });
  useFirestoreCollection({
    query: () =>
      fetchExclusiveHotel64774FromFirestore(hotelId, [
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: true,
        },
        {
          type: "filter",
          name: "partnerId",
          opr: "array-contains",
          value: partnerId,
        },
      ]),
    data: (data: any) =>
      setExclusiveOffers(
        data?.filter(
          (v: IExclusiveHotel64774) =>
            !moment(v.periodEndDate).isBefore(moment()),
        ),
      ),
    deps: [dispatch, hotelId],
    local: true,
    shouldExecute: !!hotelId,
  });
  const handleViewHotel = (periodId: string) => () => {
    history(`/hotel/${hotelId}?exclusiveOfferId=${periodId}`);
  };
  if (exclusiveOffers.length === 0) return null;
  return (
    <HotelItemWrapper>
      <div>
        <ImageGallery
          images={images || []}
          showThumbnails={false}
          showPlayButton={false}
        />
      </div>
      <div className="hotel-item-body">
        <div className="hotel-item-hotel">
          <HotelInfo
            starRating={hotel.starRating}
            hotelName={hotel.name}
            address={formatReadableAddress(hotel?.address) || ""}
            imageUrl={hotel?.defaultImage?.url || ""}
            mealType={""}
          />
        </div>

        <Space
          className="hotel-item-prices"
          align="baseline"
          style={{ marginTop: 7 }}
        >
          {orderBy(exclusiveOffers, ["periodStartDate"])?.map(
            (exclusiveOffer: IExclusiveHotel64774) => (
              <PeriodBox
                key={exclusiveOffer.id}
                descriptions={exclusiveOffer.name}
                title={`${moment(exclusiveOffer.periodStartDate).format("DD MMM YY")} -  ${moment(exclusiveOffer.periodEndDate).format("DD MMM YY")}`}
                action={handleViewHotel(exclusiveOffer.id)}
                duration={+exclusiveOffer.duration}
              />
            ),
          )}
        </Space>
      </div>
    </HotelItemWrapper>
  );
};
