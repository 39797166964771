// import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_HOTEL28355,
  COLLECTION_HOTELIMAGE94307,
} from "./firebaseConstants";

export function fetchHotelImage94307FromFirestore(
  hotelId: string,
  args?: any[],
) {
  const ref = db
    .collection(COLLECTION_HOTEL28355)
    .doc(hotelId)
    .collection(COLLECTION_HOTELIMAGE94307);
  return collectionWithArgs(ref, args || []);
}
