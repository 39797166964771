import { Rate } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { IHotel28355 } from "../../../common/types";
import { formatReadableAddress } from "../../../common/util/util";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchHotelImage94307FromFirestore } from "../../../firestore/firestoreService";
import { ImageGallery } from "..";
import { HotelFullInfoWrapper } from "./Hotel.style";
interface IProps {
  hotel: IHotel28355;
  hotelId: string;
}
export const HotelFullInfo: React.FC<IProps> = ({ hotel, hotelId }) => {
  const [images, setImages] = React.useState([]);
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () => fetchHotelImage94307FromFirestore(hotelId),
    data: setImages,
    deps: [dispatch, hotelId],
    local: true,
    shouldExecute: !!hotelId,
  });
  return (
    <HotelFullInfoWrapper>
      <div className="hotel-full-info-details">
        <span className="hotel-full-info-name">{hotel.name} </span>
        <Rate
          className="hotel-full-info-rate"
          disabled
          defaultValue={hotel.starRating}
        />
        <span className="hotel-full-info-location">
          {formatReadableAddress(hotel?.address)}
        </span>

        <div className="hotel-full-info-images">
          <ImageGallery images={images || []} />
        </div>
        <div
          className="hotel-full-info-more-details"
          style={{ marginTop: 20 }}
          dangerouslySetInnerHTML={{
            __html: hotel.descriptions || "",
          }}
        />
      </div>
    </HotelFullInfoWrapper>
  );
};
