/* eslint-disable no-console */
import React from "react";
import { orderBy } from "lodash";
import moment from "moment";
import { IRoomTypeAllotmentPriceList } from "../../../../common/types";
import { mealTypes } from "../../../../enums";
import { MealPriceSelectionWrapper } from "../Hotel.style";
import { InputRadioField } from "../../commonFields";
interface IProps {
  offer: IRoomTypeAllotmentPriceList;
  recordId: string;
  addRoom: () => void;
}

const cancelationPolicy: any = {
  REFUNDABLE: "Με δωρεάν ακύρωση μέχρι",
  NON_REFUNDABLE: "Χωρίς δωρεάν ακύρωση",
};
export const MealPriceSelection: React.FC<IProps> = ({
  offer,
  recordId,
  addRoom,
}) => (
  <MealPriceSelectionWrapper>
    <InputRadioField
      vertical
      label=""
      name={[recordId, "mealType"]}
      onChange={addRoom}
      options={[
        {
          value: offer.mealsType || "",
          label: (
            <div className="mealPriceSelection-include">
              {mealTypes[offer.mealsType]?.include || ""}{" "}
              {offer.mealsPrices.length > 0 ? "+ €0" : ""}
            </div>
          ),
        },
        ...orderBy(offer.mealsPrices, ["price"])?.map((meal: any) => ({
          value: meal.name,
          label: `${mealTypes[meal.name]?.label || ""} + €${meal.price}`,
        })),
      ]}
    />
    <div className="mealPriceSelection-payment">
      {" "}
      {offer.downPaymentAmount
        ? `Κρατήστε σήμερα και πληρώστε μόνο  ${offer.downPaymentAmount}%`
        : ""}
    </div>
    <div className="mealPriceSelection-policy">
      {cancelationPolicy[offer.cancelationPolicy] || ""}{" "}
      {offer.cancelationPolicy
        ? `(${moment(offer.refundableDate).format("DD/MM/YYYY")})`
        : ""}
    </div>
  </MealPriceSelectionWrapper>
);
