import styled from "styled-components";
export const HomePageWrapper = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .scroll-full {
    overflow-y: auto;
    height: 100%;
  }
  .heading {
    color: #fff;
    width: 100%;
    text-align: center;
    padding-top: 40px;
  }
  .standard-home-container {
    width: 100%;
    position: relative;
    .standard-full {
      position: relative;
    }
    .bg-blue {
      color: #c0e0eb;
    }
    .bg-hotel {
      background: url(/assets/images/hotels.jpg) top/cover fixed;
      opacity: 0.9;
      @media (max-width: 600px) {
        background: #37a7cd;
        opacity: 1;
      }
    }
    .bgimg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden !important;
      z-index: -10;
    }
    .flights-search-header {
      font-size: 21px;
      text-align: center;
      margin: 30px;
      font-family: Montserrat, sans-serif, "Open Sans";
      font-weight: 700;
      .or-color {
        color: #ffc926;
      }
    }
    .space-footer {
      height: 100px;
    }
    .full-height {
      position: relative;
      z-index: 0;
      video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        transform: translateX(-50%) translateY(-50%);
        background: url("//s3-eu-west-1.amazonaws.com/eventape/poupas/Sunset-Lapse.jpg")
          no-repeat;
        background-size: cover;
        transition: 1s opacity;
        @media (max-width: $screen-md) {
          display: none;
        }
      }
    }
  }
  .section {
    text-align: center;
    width: 100%;
    .pad-small {
      padding-top: 60px;
    }
    .section-heading {
      padding-bottom: 30px;
      h2 {
        font-size: 3.1em;
        font-weight: 700;
        color: #212121;
        padding-bottom: 2%;
        font-family: Montserrat, sans-serif, "Open Sans";
      }
      .small {
        font-size: 14px;
      }
      span {
        display: block;
      }
    }
    &-prices {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    &-gray {
      background-color: #f4f4f4;
    }
  }
  .blue-color {
    color: #198dcc;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem;
    font-size: 36px;
    padding: 0;
    font-weight: 100;
  }
  .pad-bottom {
    padding-bottom: 2%;
  }
  .pad-top {
    padding-top: 2%;
  }
  .section-gray {
    background-color: #f4f4f4;
  }
  .container {
    margin: 0 auto;
    max-width: 940px;
    width: 100%;
  }
  .footer {
    text-align: center;
    padding: 20px;
  }
`;
