/* eslint-disable no-console */
import { some } from "lodash";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IPackageHotelPublic, IRoomInput } from "../../../common/typings";
import { Form, Select, Space } from "../../atoms";
import { TravellerSelectorsWrapper } from "./PackageViewFrontEnd.style";
const { Option } = Select;
export interface ITravellerSelectorsProps {
  setRooms: (Rooms: IRoomInput[]) => void;
  rooms: IRoomInput[];
  selectedHotels: IPackageHotelPublic[];
  isMobile: boolean;
}
const isRoomTypeAvailable = (hotels: IPackageHotelPublic[], type: string) =>
  some(hotels, [type, 0]);

export const TravellerSelectors: React.FC<ITravellerSelectorsProps> = ({
  setRooms,
  rooms,
  selectedHotels,
  isMobile,
}) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const handleChangeNumbersRooms = (value: string) => {
    const currentRooms: IRoomInput[] = [];
    if (value === "1") {
      currentRooms.push(rooms[0]);
    } else if (value === "2") {
      currentRooms.push(
        rooms[0],
        rooms[1] || { roomType: "double", numberChilds: 0 },
      );
    } else {
      currentRooms.push(
        rooms[0],
        rooms[1] || { roomType: "double", numberChilds: 0 },
        rooms[2] || { roomType: "double", numberChilds: 0 },
      );
    }
    form.setFieldsValue({ numberOfRooms: value, rooms: currentRooms });
    setRooms(currentRooms);
  };
  const handleOnValuesChange = (value: any, allValues: any) => {
    const selectedRooms: any = allValues.rooms.map((room: any) => {
      if (room.roomType !== "double") {
        room.numberChilds = 0;
        return room;
      }
      return room;
    });
    setRooms(selectedRooms);
  };
  React.useEffect(() => {
    const currentRooms = [{ roomType: "double", numberChilds: 0 }];
    form.setFieldsValue({ numberOfRooms: "1", rooms: currentRooms });
    setRooms(currentRooms);
  }, [selectedHotels]);
  return (
    <TravellerSelectorsWrapper>
      <div className="traveller-selectors-title">
        <FormattedMessage
          description="How many travelling"
          defaultMessage="How many travelling"
          id="PkASeu"
        />
      </div>
      <div className="traveller-selectors-list">
        <Form
          name="horizontal_login"
          layout="vertical"
          form={form}
          onValuesChange={handleOnValuesChange}
          initialValues={{ rooms }}
        >
          <Form.Item
            name="numberOfRooms"
            label={formatMessage({
              description: "Number of Rooms",
              defaultMessage: "Number of Rooms",
              id: "CoAg+C",
            })}
            initialValue="1"
          >
            <Select onChange={handleChangeNumbersRooms}>
              <Option value="1">
                <FormattedMessage
                  description="1 Room"
                  defaultMessage="1 Room"
                  id="S1e5h1"
                />
              </Option>
              <Option value="2">
                <FormattedMessage
                  description="2 Rooms"
                  defaultMessage="2 Rooms"
                  id="IHERsC"
                />
              </Option>
              <Option value="3">
                <FormattedMessage
                  description="3 Rooms"
                  defaultMessage="3 Rooms"
                  id="xO8xuQ"
                />
              </Option>
            </Select>
          </Form.Item>
          <Form.List name="rooms">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    className="full-width traveller-selectors-room"
                    key={index}
                    align={index === 0 ? "center" : "baseline"}
                  >
                    <div className="traveller-selectors-des">
                      <FormattedMessage
                        description="Room"
                        defaultMessage="Room"
                        id="ExGOkE"
                      />{" "}
                      {index + 1}
                    </div>
                    <Form.Item
                      name={[index, "roomType"]}
                      label={
                        index === 0
                          ? formatMessage({
                              description: "Adult(s)",
                              defaultMessage: "Adult(s)",
                              id: "V3FAbU",
                            })
                          : ""
                      }
                    >
                      <Select
                        placeholder={formatMessage({
                          description: "Room Type",
                          defaultMessage: "Room Type",
                          id: "btrEBG",
                        })}
                      >
                        {!isRoomTypeAvailable(
                          selectedHotels,
                          "singleRoomPricePublic",
                        ) && (
                          <Option value="single">
                            <FormattedMessage
                              description="(1 Adult)"
                              defaultMessage="(1 Adult)"
                              id="yhrUcu"
                            />
                            {!isMobile &&
                              formatMessage({
                                description: "Single Room",
                                defaultMessage: "Single Room",
                                id: "wya+Gv",
                              })}
                          </Option>
                        )}
                        <Option value="double">
                          <FormattedMessage
                            description="(2 Adults)"
                            defaultMessage="(2 Adults)"
                            id="bXkrX/"
                          />
                          {!isMobile &&
                            formatMessage({
                              description: "Double Room",
                              defaultMessage: "Double Room",
                              id: "SQOSAp",
                            })}
                        </Option>
                        {!isRoomTypeAvailable(
                          selectedHotels,
                          "tripleRoomPricePublic",
                        ) && (
                          <Option value="triple">
                            <FormattedMessage
                              description="(3 Adults)"
                              defaultMessage="(3 Adults)"
                              id="BFZ/t6"
                            />
                            {!isMobile &&
                              formatMessage({
                                description: "Triple Room",
                                defaultMessage: "Triple Room",
                                id: "PuM+Ls",
                              })}
                          </Option>
                        )}
                        {!isRoomTypeAvailable(
                          selectedHotels,
                          "quadrupleRoomPricePublic",
                        ) && (
                          <Option value="quadruple">
                            <FormattedMessage
                              description="(4 Adults)"
                              defaultMessage="(4 Adults)"
                              id="KmFhTY"
                            />
                            {!isMobile &&
                              formatMessage({
                                description: "Quadruple Room",
                                defaultMessage: "Quadruple Room",
                                id: "JVViU2",
                              })}
                          </Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[index, "numberChilds"]}
                      label={
                        index === 0
                          ? formatMessage({
                              description: "Child(s)",
                              defaultMessage: "Child(s)",
                              id: "RzbH5x",
                            })
                          : ""
                      }
                    >
                      <Select disabled={rooms[index].roomType !== "double"}>
                        {" "}
                        <Option value={0}>
                          <FormattedMessage
                            description="No Child"
                            defaultMessage="No Child"
                            id="jk0Y7F"
                          />
                        </Option>
                        {!isRoomTypeAvailable(
                          selectedHotels,
                          "firstChildPricePublic",
                        ) && (
                          <Option value={1}>
                            <FormattedMessage
                              description="1 Child"
                              defaultMessage="1 Child"
                              id="0tTAaA"
                            />
                          </Option>
                        )}
                        {!isRoomTypeAvailable(
                          selectedHotels,
                          "secondChildPricePublic",
                        ) && (
                          <Option value={2}>
                            <FormattedMessage
                              description="2 Children"
                              defaultMessage="2 Children"
                              id="HNpbu7"
                            />
                          </Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </div>
    </TravellerSelectorsWrapper>
  );
};
