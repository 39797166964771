/* eslint-disable no-console */
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Input } from "../../atoms";
import { BookingContactInformationWrapper } from "./PackageBookingFrontEnd.style";
interface IProps {
  partnerId?: string;
}
export const BookingContactInformation: React.FC<IProps> = ({ partnerId }) => {
  const { formatMessage } = useIntl();
  return (
    <BookingContactInformationWrapper>
      <div className="booking-contact-title">
        <FormattedMessage
          description="CONTACT INFORMATION FOR ALL"
          defaultMessage="CONTACT INFORMATION FOR ALL"
          id="d0TAeS"
        />
      </div>
      <div className="booking-contact-des">
        <FormattedMessage
          description="CONTACT INFORMATION description"
          defaultMessage="Please Confirm"
          id="CN8EJc"
        />{" "}
      </div>
      <Form.Item
        name={["customer", "name"]}
        label={formatMessage({
          description: "Full Name",
          defaultMessage: "Full Name",
          id: "KHWx3j",
        })}
        rules={[
          {
            required: true,
            message: formatMessage({
              description: "Missing Full Name",
              defaultMessage: "Missing Full Name",
              id: "GSKNmV",
            }),
          },
        ]}
      >
        <Input
          placeholder={formatMessage({
            description: "Full Name",
            defaultMessage: "Full Name",
            id: "KHWx3j",
          })}
        />
      </Form.Item>
      <Form.Item
        name={["customer", "email"]}
        label={formatMessage({
          description: "Email",
          defaultMessage: "Email",
          id: "y8zzVx",
        })}
        rules={[
          {
            required: true,
            message: formatMessage({
              description: "Missing Email",
              defaultMessage: "Missing Email",
              id: "qJEn+v",
            }),
          },
        ]}
      >
        <Input
          placeholder={formatMessage({
            description: "Email",
            defaultMessage: "Email",
            id: "y8zzVx",
          })}
        />
      </Form.Item>
      <Form.Item
        name={["customer", "confirmEmail"]}
        label={formatMessage({
          description: "Confirm Email",
          defaultMessage: "Confirm Email",
          id: "QS6APJ",
        })}
        rules={[
          {
            required: true,
            message: formatMessage({
              description: "Missing Confirm Email",
              defaultMessage: "Missing Confirm Email",
              id: "Augi7R",
            }),
          },
        ]}
      >
        <Input
          placeholder={formatMessage({
            description: "Confirm Email",
            defaultMessage: "Confirm Email",
            id: "QS6APJ",
          })}
        />
      </Form.Item>
      <Form.Item
        name={["customer", "telephone"]}
        label={formatMessage({
          description: "Tel No.",
          defaultMessage: "Tel No.",
          id: "0/LF7H",
        })}
        rules={[
          {
            required: true,
            message: formatMessage({
              description: "Missing Confirm Tel No.",
              defaultMessage: "Missing Confirm Tel No.",
              id: "6415L+",
            }),
          },
        ]}
      >
        <Input
          placeholder={formatMessage({
            description: "Tel No.",
            defaultMessage: "Tel No.",
            id: "0/LF7H",
          })}
        />
      </Form.Item>

      {partnerId && (
        <Form.Item
          name={["customer", "id"]}
          label={"Ταυτότητα"}
          rules={[
            {
              required: true,
              message: "Ταυτότητα",
            },
          ]}
        >
          <Input placeholder={"Ταυτότητα"} />
        </Form.Item>
      )}
    </BookingContactInformationWrapper>
  );
};
