import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import {
  COLLECTION_EXCLUSIVEHOTEL64774,
  COLLECTION_HOTEL28355,
} from "./firebaseConstants";

export function fetchExclusiveHotel64774FromFirestore(
  hotelId: string,
  args?: IQueryConfigArgs[],
) {
  const ref = db
    .collection(COLLECTION_HOTEL28355)
    .doc(hotelId)
    .collection(COLLECTION_EXCLUSIVEHOTEL64774);
  return collectionWithArgs(ref, args || []);
}
export function listenToExclusiveHotel64774FromFirestore(
  hotelId: string,
  id: string,
) {
  return db
    .collection(COLLECTION_HOTEL28355)
    .doc(hotelId)
    .collection(COLLECTION_EXCLUSIVEHOTEL64774)
    .doc(id);
}
