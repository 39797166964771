import React from "react";
import { useParams } from "react-router-dom";
import { HotelBookingConfirm } from "../../App/components/organisms";
interface IProps {
  onRequest?: boolean;
}
const HotelBookingConfirmation: React.FC<IProps> = ({ onRequest }) => {
  const params = useParams();
  const bookingId = params.bookingId || "";
  return <HotelBookingConfirm bookingId={bookingId} onRequest={onRequest} />;
};

export default HotelBookingConfirmation;
