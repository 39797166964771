import { IAmenity2092 } from "../../../common/types";
import {
  CREATE_AMENITY2092,
  UPDATE_AMENITY2092,
  DELETE_AMENITY2092,
  FETCH_AMENITY2092,
  LISTEN_TO_SELECTED_AMENITY2092,
  FILTER_AMENITY2092,
} from "./amenity2092Constants";

const initialState = {
  amenity2092: [],
  amenity2092Map: {},
  selectedAmenity2092: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterAmenity2092: {},
};

export default function amenity2092Reducer(
  state = initialState,
  { type, payload }: any,
) {
  switch (type) {
    case CREATE_AMENITY2092:
      return {
        ...state,
        amenity2092: [...state.amenity2092, payload],
      };
    case UPDATE_AMENITY2092:
      return {
        ...state,
        amenity2092: [
          ...state.amenity2092.filter(
            (evt: IAmenity2092) => evt.id !== payload.id,
          ),
          payload,
        ],
      };
    case DELETE_AMENITY2092:
      return {
        ...state,
        amenity2092: [
          ...state.amenity2092.filter(
            (evt: IAmenity2092) => evt.id !== payload,
          ),
        ],
      };
    case FETCH_AMENITY2092:
      return {
        ...state,
        amenity2092: payload,
        amenity2092Map: payload.reduce((acc: any, item: any) => {
          acc[item.id] = item;
          return acc;
        }, {}),
      };
    case LISTEN_TO_SELECTED_AMENITY2092:
      return {
        ...state,
        selectedAmenity2092: payload,
      };
    case FILTER_AMENITY2092:
      return {
        ...state,
        filterAmenity2092: payload || {},
      };
    default:
      return state;
  }
}
