/* eslint-disable no-console */
import React from "react";
import { FormattedMessage } from "react-intl";
import { IFlight } from "../../../common/typings";
import { FlightLeg } from "../../molecules";
import { PackageFlightsWrapper } from "./PackageViewFrontEnd.style";

export interface IPackageFlightsFrontendProps {
  flights: IFlight[];
  isMobile?: boolean;
  noHeader?: boolean;
}
export const PackageFlights: React.FC<IPackageFlightsFrontendProps> = ({
  flights,
  isMobile,
  noHeader,
}) => {
  if (flights.length === 0) return <div></div>;
  return (
    <PackageFlightsWrapper>
      {!noHeader && (
        <div className="package-flights-title">
          <FormattedMessage
            description="Flight Details"
            defaultMessage="Flight Details"
            id="W7OrHr"
          />
        </div>
      )}
      <div className="package-flights-list">
        {flights?.map((flight) => (
          <FlightLeg
            key={flight.id}
            departureDate={flight.departureDate}
            departureAirportCode={flight.departureAirportCode}
            arrivalAirportCode={flight.arrivalAirportCode}
            duration={flight.duration || ""}
            arrivalDate={flight.arrivalDate}
            airlineCode={flight.airlineCode}
            isMobile={isMobile}
          />
        ))}
      </div>
    </PackageFlightsWrapper>
  );
};
