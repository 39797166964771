import React from "react";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { BookingCustomerInput } from "../../molecules/Booking/BookingCustomerInput";
import { BookingCommentInput } from "../../molecules/Booking/BookingCommentInput";
import { BookingHotelPaymentInput } from "../../molecules/Booking/BookingHotelPaymentInput";
import { Form, notification } from "../../atoms";
import { HotelCart, Loader } from "../../molecules";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import {
  listenToBooking30512HotelFromFirestore,
  updateBooking30512HotelInFirestore,
} from "../../../firestore/firestoreService";
import { IHotelBookingInput } from "../../../common/types";
import { BookingRoomInput } from "../../molecules/Booking";
import { jccConvertAmount } from "../../../common/util/util";
import { HotelWrapper } from "./Hotel.style";

export const HotelBooking: React.FC = ({}) => {
  const [form] = Form.useForm();
  const formRef = React.useRef<any>();
  const history = useNavigate();
  const [showCart, setShowCart] = React.useState(true);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const formRefPartialPayment = React.useRef<any>();
  const dispatch = useDispatch();
  const params = useParams();
  const [booking, setBooking] = React.useState<IHotelBookingInput>();
  const bookingId = params.bookingId || "";
  const handleOnFinish = (type: string) => async () => {
    try {
      const values: IHotelBookingInput = await form.validateFields();
      // eslint-disable-next-line no-console
      console.log(values.rooms);
      await updateBooking30512HotelInFirestore(bookingId, {
        ...booking,
        paymentStatus: type === "ON_REQUEST" ? "ON_REQUEST" : "IN_PROGRESS",
        comment: values?.comment || "",
        customer:
          {
            ...values.customer,
            dateOfBirth: moment(values.customer?.dateOfBirth || "").format(
              "YYYY-MM-DD",
            ),
          } || null,
        rooms: booking?.rooms.map((room: any) => ({
          ...room,
          mainGuest: {
            ...values.roomsMainGuest[room.id],
            dateOfBirth: moment(
              values.roomsMainGuest[room.id]?.dateOfBirth || "",
            ).format("YYYY-MM-DD"),
          },
        })),
        initialPayment: type,
      });
      if (type === "PARIETAL_PAYMENT") {
        formRefPartialPayment.current.submit();
      } else if (type === "FULL_PAYMENT") {
        formRef.current.submit();
      } else {
        history(`/hotel-booking-on-request-confirm/${bookingId}`);
      }
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  const handelShowCart = () => {
    setShowCart((v) => !v);
  };
  userFirestoreDoc({
    query: () => listenToBooking30512HotelFromFirestore(bookingId),
    data: setBooking,
    shouldExecute: !!bookingId,
    deps: [dispatch, bookingId],
    local: true,
  });
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
  }, [isTabletOrMobile]);
  if (!booking) return <Loader title="Booking Loading" color={"#fff"} />;
  return (
    <HotelWrapper>
      <div className="hotel-body">
        <div className="hotel-result">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <BookingCustomerInput form={form} />
            {booking.rooms.map((room) => (
              <BookingRoomInput
                key={room.id}
                title={""}
                hotelId={booking.hotelId}
                room={room}
                showDateOfBirth
              />
            ))}
            <BookingCommentInput />
            <BookingHotelPaymentInput
              onSubmit={handleOnFinish}
              totalPayment={booking.totalPayment}
              partialPayment={booking.partialPayment}
              onRequest={!!booking.onRequest}
              loading={false}
              isTabletOrMobile={isTabletOrMobile}
            />
          </Form>
        </div>
        <div className={classNames("hotel-right", { hideRight: showCart })}>
          <HotelCart
            rooms={booking.rooms}
            startDate={booking.checkIn}
            hotelId={booking.hotelId}
            exclusiveOfferId={booking.exclusiveOfferId}
            checkInCheckOut={`${booking.checkIn} - ${booking.checkOut}`}
            totalPrice={booking.totalPayment}
            sellPrice={booking.sellPrice}
            netPrice={booking.netPrice}
            onShowCart={handelShowCart}
            isTabletOrMobile={isTabletOrMobile}
          />
        </div>
      </div>
      <form
        method="post"
        name="paymentForm"
        id="fullPaymentForm"
        ref={(ref) => (formRef.current = ref)}
        action="https://jccpg.jccsecure.com/EcomPayment/RedirectAuthLink"
      >
        <input type="hidden" name="Version" value="1.0.0" />
        <input type="hidden" name="MerID" value="0030398036" />
        <input type="hidden" name="AcqID" value="402971" />
        <input
          type="hidden"
          name="MerRespURL"
          value={`https://us-central1-poupas-9e426.cloudfunctions.net/jccResponse/${bookingId}`}
        />
        <input
          type="hidden"
          name="PurchaseAmt"
          value={jccConvertAmount(booking?.totalPayment || 0)}
        />
        <input type="hidden" name="PurchaseCurrency" value="978" />
        <input type="hidden" name="PurchaseCurrencyExponent" value="2" />
        <input type="hidden" name="OrderID" value={bookingId} />
        <input type="hidden" name="CaptureFlag" value="A" />
        <input
          type="hidden"
          name="Signature"
          value={booking?.signatureTotal || ""}
        />
        <input type="hidden" name="SignatureMethod" value="SHA1" />
      </form>
      <form
        method="post"
        name="paymentForm"
        id="partialPaymentForm"
        ref={(ref) => (formRefPartialPayment.current = ref)}
        action="https://jccpg.jccsecure.com/EcomPayment/RedirectAuthLink"
      >
        <input type="hidden" name="Version" value="1.0.0" />
        <input type="hidden" name="MerID" value="0030398036" />
        <input type="hidden" name="AcqID" value="402971" />
        <input
          type="hidden"
          name="MerRespURL"
          value={`https://us-central1-poupas-9e426.cloudfunctions.net/jccResponse/${bookingId}`}
        />
        <input
          type="hidden"
          name="PurchaseAmt"
          value={jccConvertAmount(booking?.partialPayment || 0)}
        />
        <input type="hidden" name="PurchaseCurrency" value="978" />
        <input type="hidden" name="PurchaseCurrencyExponent" value="2" />
        <input type="hidden" name="OrderID" value={bookingId} />
        <input type="hidden" name="CaptureFlag" value="A" />
        <input
          type="hidden"
          name="Signature"
          value={booking?.signaturePartial || ""}
        />
        <input type="hidden" name="SignatureMethod" value="SHA1" />
      </form>
    </HotelWrapper>
  );
};
