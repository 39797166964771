import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_CITY83431 } from "./firebaseConstants";

export function fetchCity83431FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_CITY83431);
  return collectionWithArgs(ref, args || []);
}
export function listenToCity83431FromFirestore(id: string) {
  return db.collection(COLLECTION_CITY83431).doc(id);
}
