import { CloseOutlined } from "@ant-design/icons";
import React from "react";
import { mealTypes } from "../../../enums";
import { Button } from "../../atoms/Button/Button";
import { CartItemWrapper } from "./CartItem.style";

export interface ICartItemProps {
  title: string;
  adults: number;
  child: number;
  info: string;
  price: number;
  onDelete?: () => void;
  currency: string;
}

const childMap: { [key: number]: string | null } = {
  0: null,
  1: " 1 Child",
  2: " 2 Childs",
};
const adultMap: { [key: number]: string } = {
  1: " 1 Adult",
  2: " 2 Adults",
  3: " 3 Adults",
  4: " 4 Adults",
};
export const CartItem: React.FC<ICartItemProps> = ({
  title,
  adults,
  child,
  info,
  price,
  onDelete,
  currency,
}) => {
  const getNumberOfAdultsChildsDisplay = (adultNo: number, childNo: number) =>
    [adultMap[adultNo], childMap[childNo]].filter((f) => !!f).join(", ");
  return (
    <CartItemWrapper>
      <div className="cart-item-info">
        <span className="cart-item-title">{title}</span>
        <span className="cart-item-passenger">
          ({getNumberOfAdultsChildsDisplay(adults, child)})
        </span>
        <span className="cart-item-include">{mealTypes[info]?.label}</span>
      </div>
      <div className="cart-item-price">
        {currency}
        {price}
      </div>
      {onDelete && (
        <Button className="cart-item-close" type="link" onClick={onDelete}>
          {" "}
          <CloseOutlined />
        </Button>
      )}
    </CartItemWrapper>
  );
};
