/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-var-requires */
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { loadStateFromLocalStorage } from "../../common/util/localStorage";
import rootReducer from "../reducers";
import { verifyAuth } from "../data/auth/authActions";
const configureStore = (preloadedState: any) => {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancer = composeWithDevTools(...storeEnhancers);

  const store = createStore(rootReducer(), preloadedState, composedEnhancer);

  store.dispatch(verifyAuth());

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("../reducers", () => {
        const newRootReducer = require("../reducers").default;
        store.replaceReducer(newRootReducer);
      });
    }
  }
  return store;
};

const persistedState = loadStateFromLocalStorage();
export const appStore = configureStore(persistedState);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof appStore.dispatch;
