/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import { IPackage } from "../../../common/typings";
import { ImageGallery, PackageAction, PackageInfo } from "../../molecules";
import { PackageItemWrapper } from "./PackageListFrontEnd.style";
export interface IPackageItemProps {
  travelPackage: IPackage;
  isMobile: boolean;
  partner?: string;
}
type DiscountType = "pasydyDiscount" | "poedDiscount";
const discountFieldName = process.env.REACT_APP_SITE_DISCOUNT as DiscountType;
const PackageItem: React.FC<IPackageItemProps> = ({
  travelPackage,
  isMobile,
  partner,
}) => {
  const history = useNavigate();
  const handelSelect = () => {
    if (isMobile) {
      history(`/package/${travelPackage?.id}`);
    } else {
      const win: any = window.open(`/package/${travelPackage?.id}`, "_blank");
      win.focus();
    }
    // history.push(`/package/${travelPackage.id}`);
  };
  return (
    <PackageItemWrapper>
      <div>
        <ImageGallery
          images={travelPackage?.images || []}
          showThumbnails={false}
          showPlayButton={false}
        />
      </div>
      <div>
        <PackageInfo
          title={travelPackage?.name || ""}
          departureCity={travelPackage?.departureCity || ""}
          departureDate={travelPackage?.departureDate || ""}
          returnDate={travelPackage?.returnDate || ""}
          serviceInclude={travelPackage?.serviceInclude || []}
          categories={travelPackage?.categories || []}
          partner={partner}
        />
      </div>
      <div>
        <PackageAction
          price={travelPackage?.minPricePerAdult || 0}
          discount={+(travelPackage?.[discountFieldName] || 0)}
          currency="€"
          onSelect={handelSelect}
        />
      </div>
    </PackageItemWrapper>
  );
};

export default PackageItem;
