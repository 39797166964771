/* eslint-disable no-console */
import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { DoubleRightOutlined } from "@ant-design/icons";
import { IHotel28355, IHotelBookingPhysicalRoom } from "../../../common/types";
import {
  HotelCart,
  HotelFullInfo,
  HotelPriceList,
  HotelSearchAvailability,
  Loader,
} from "../../molecules";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToHotel28355FromFirestore } from "../../../firestore/firestoreService/Hotel28355Store";
import { Button } from "../../atoms";
import { HotelWrapper } from "./Hotel.style";

export const HotelDetails: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [netPrice, setNetPrice] = React.useState(0);
  const [sellPrice, setSellPrice] = React.useState(0);
  const [showCart, setShowCart] = React.useState(true);
  const queryParams = new URLSearchParams(location.search);
  const [hotel, setHotel] = React.useState<IHotel28355>();
  const [selectedRooms, setSelectedRooms] = React.useState<
    IHotelBookingPhysicalRoom[]
  >([]);
  const hotelId = params.hotelId || "";
  const startDateParam = queryParams.get("startDate");
  const exclusiveOfferIdParam = queryParams.get("exclusiveOfferId");
  const adultNumbersParam = queryParams.get("adultNumbers");
  const childrenNumberParam = queryParams.get("childrenNumber");
  const infantParam = queryParams.get("infant");
  const checkInCheckOutParam = queryParams.get("checkInCheckOut");
  const reCallParam = queryParams.get("reCall");
  const handelShowCart = () => {
    setShowCart((v) => !v);
  };
  const handleSelectedRoom = (room: IHotelBookingPhysicalRoom) => {
    setSelectedRooms([room]);
  };
  const handleRemoveRoom = (roomId: string) => {
    setSelectedRooms([...selectedRooms.filter((r) => r.id !== roomId)]);
  };
  userFirestoreDoc({
    query: () => listenToHotel28355FromFirestore(hotelId),
    data: setHotel,
    shouldExecute: !!hotelId,
    deps: [dispatch, hotelId],
    local: true,
  });
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
  }, [isTabletOrMobile]);

  React.useEffect(() => {
    if (selectedRooms) {
      const total = selectedRooms.reduce(
        (acc, cur) => +acc + +cur.totalPrice,
        0,
      );
      const net = selectedRooms.reduce((acc, cur) => +acc + +cur.netPrice, 0);
      const sell = selectedRooms.reduce((acc, cur) => +acc + +cur.sellPrice, 0);
      setTotalPrice(total);
      setNetPrice(net);
      setSellPrice(sell);
    }
  }, [selectedRooms]);
  if (!hotel) return <Loader color={""} />;
  return (
    <HotelWrapper>
      <div className="hotel-body">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{hotel?.name}</title>
          <meta property="og:image" content={"dsdsds/sdsdsds"} />
          <meta property="og:type" content="article wewewew" />
          <meta name="description" content="Helmet application" />
        </Helmet>

        <div className="hotel-result">
          <HotelFullInfo hotel={hotel} hotelId={hotelId} />
          <HotelSearchAvailability
            hotelId={hotelId}
            exclusiveOfferIdParam={exclusiveOfferIdParam || ""}
            startDate={startDateParam || ""}
            adultNumbers={+(adultNumbersParam || 2)}
            childrenNumber={+(childrenNumberParam || 0)}
            infant={+(infantParam || 0)}
          />
          {startDateParam && exclusiveOfferIdParam && (
            <HotelPriceList
              offerId={exclusiveOfferIdParam}
              hotelId={hotelId}
              addRoom={handleSelectedRoom}
              removeRoom={handleRemoveRoom}
              startDate={startDateParam || ""}
              adultNumbers={+(adultNumbersParam || "1")}
              childrenNumber={+(childrenNumberParam || "0")}
              infant={+(infantParam || 0)}
              isMobile={isMobile}
              reCall={reCallParam || ""}
            />
          )}
          {isTabletOrMobile && (
            <div style={{ padding: "0 10px" }}>
              <Button
                className="hotel-booking-details-btn-btn"
                onClick={handelShowCart}
              >
                Λεπτομέρειες Κράτησης
                <DoubleRightOutlined />
              </Button>
            </div>
          )}
          <div style={{ height: 200 }}></div>
          {isTabletOrMobile && (
            <div className="hotel-booking-details">
              <div className="hotel-booking-details-btn-label">
                {checkInCheckOutParam}
              </div>
              <div className="hotel-booking-details-btn-label">
                Total Price
                <span>€{totalPrice}</span>
              </div>
            </div>
          )}
        </div>
        <div className={classNames("hotel-right", { hideRight: showCart })}>
          <HotelCart
            rooms={selectedRooms}
            removeRoom={handleRemoveRoom}
            startDate={startDateParam || ""}
            hotelId={hotelId}
            exclusiveOfferId={exclusiveOfferIdParam || ""}
            checkInCheckOut={checkInCheckOutParam || ""}
            totalPrice={totalPrice}
            netPrice={netPrice}
            sellPrice={sellPrice}
            onShowCart={handelShowCart}
            isTabletOrMobile={isTabletOrMobile}
          />
        </div>
      </div>
    </HotelWrapper>
  );
};
