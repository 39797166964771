/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
import cuid from "cuid";
import firebase from "firebase/compat/app";
import { get } from "lodash";
import moment from "moment";
import "moment/locale/el";
import { countries } from "../../enums";
import {
  IAddress,
  ICity,
  IPackageHotelPublic,
  IPassenger,
  IRoom,
  IRoomInput,
} from "../typings";
import { IExclusiveHotelRoomType47552 } from "../types";
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getFileExtension = (filename: string) =>
  // eslint-disable-next-line no-bitwise
  filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);

export const getFlightDuration = (
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  const duration = moment.utc(endDate.diff(startDate)).format("HH:mm");
  return duration;
};

export const getPackageDuration = (
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  const duration1 = moment.duration(endDate.diff(startDate)).asDays();
  const duration = Math.round(duration1);
  return {
    nights: duration,
    days: duration + 1,
    daysNights: `${duration + 1} Ημέρες / ${duration} Νύκτες `,
  };
};

export const formatReadableAddress = (address: IAddress | any): string => {
  if (!address) return "";
  const streetLine1 = get(address, "streetLine1") || "";
  const streetLine2 = get(address, "streetLine2") || "";
  const city = get(address, "city.name") || "";
  const countryName = get(address, "country.name") || "";
  return [streetLine1, streetLine2, city, countryName]
    .filter((f) => !!f)
    .join(", ");
};

export const formatReadableCity = (city: ICity | undefined): string => {
  if (!city) return "";
  const name = get(city, "name") || "";
  const countryName = countries[city.countryCode]?.label || "";
  return [name, countryName].filter((f) => !!f).join(", ");
};

export const convertRoomsToPassenger = (rooms: IRoom[]): IPassenger[] => {
  const roomTypes: { [key: string]: number } = {
    single: 1,
    double: 2,
    triple: 3,
    quadruple: 4,
  };
  const passengers: IPassenger[] = [];

  rooms.forEach((room, index) => {
    const adultNumbers = roomTypes[room.roomType];
    passengers.push(
      ...Array<IPassenger>(adultNumbers).fill({
        id: cuid(),
        type: "ADULT",
        title: "",
        firstName: "",
        lastName: "",
        roomId: room.id,
        roomName: `Δωμάτιο ${index + 1}`,
      }),
    );
    passengers.push(
      ...Array<IPassenger>(room.numberChilds).fill({
        id: cuid(),
        type: "CHILD",
        title: "",
        firstName: "",
        lastName: "",
        roomId: room.id,
      }),
    );
  });
  return passengers;
};

export const getYearMonthsOptions = () =>
  Array.apply(0, Array(12)).map((_, i) => ({
    label: moment().locale("el").add(i, "month").format("MMMM - YYYY"),
    value: moment().add(i, "month").format("MM-YYYY"),
  }));

export const urlStringToOBject = (search: string) => {
  if (!search) return {};

  // Remove the leading '?' from the query string
  const queryString = search[0] === "?" ? search.substring(1) : search;

  const queryParts = queryString.split("&").reduce((acc: any, currentPair) => {
    const [key, value] = currentPair.split("=");
    // Use decodeURIComponent to decode both keys and values to handle encoding correctly
    acc[decodeURIComponent(key)] = decodeURIComponent(value);
    return acc;
  }, {});

  return queryParts;
};

export const dateFormatFirestore = (data: any) => {
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }
  return data;
};

const roomTypes: { [key: string]: number } = {
  single: 1,
  double: 2,
  triple: 3,
  quadruple: 4,
};
export const getRoomCost = (
  room: IRoomInput,
  hotel: IPackageHotelPublic,
  discount: number,
): number => {
  const adultPrice = get(hotel, `${room.roomType}RoomPricePublic`, 0);

  const numberOfAdults = get(roomTypes, room.roomType, 0);

  const firstChildPrice =
    room.numberChilds > 0 ? get(hotel, "firstChildPricePublic", 0) : 0;
  const secondChildPrice =
    room.numberChilds > 1 ? get(hotel, "secondChildPricePublic", 0) : 0;
  const totalPrice =
    adultPrice * numberOfAdults + firstChildPrice + secondChildPrice;
  const currentDiscount: number = Math.ceil((+totalPrice * +discount) / 100);
  const currentPrice = +totalPrice - +currentDiscount;
  return currentPrice;
};

export const jccConvertAmount = (amount: number): string => {
  const purchaseAmt = `${amount}.00`;
  const purchaseAmtPadStart = purchaseAmt.padStart(13, "0");
  return purchaseAmtPadStart.replace(/[.]/g, "");
};

export function generateCombinations(
  adults: number,
  children: number,
  record: IExclusiveHotelRoomType47552,
) {
  const combinations = [];

  const getPrice = (adults: number, child?: number) => {
    let price = 0;
    if (adults <= 2) {
      price = record.netPrice;
    } else {
      price = convertObjectToArray(record.extraAdult).reduce(
        (acc: any, record: any) => {
          const adultId = +(record.label.split("_")?.[2] || 0);
          if (adultId < adults - 1) {
            return +acc + +record.value.price;
          }
          return +acc;
        },
        +record.netPrice,
      );
    }
    if (child) {
      price = convertObjectToArray(record.extraChild).reduce(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (acc: number, record: any) => {
          const childId = +(record.label.split("_")?.[2] || 0);
          if (childId <= child) {
            return +acc + +record.value.price;
          }
          return acc;
        },
        price,
      );
    }
    return price;
  };
  const getMealPrice = (adultNumber: number, childNumber?: number) => {
    const mealsPrices: any = [];
    convertObjectToArray(record.meals).forEach((item: any) => {
      console.log(item);
      if (+item.value.AdultPrice !== 0) {
        mealsPrices.push({
          name: item.label,
          price:
            +(item.value.AdultPrice || 0) * adultNumber +
            +(item.value.childPrice || 0) * (childNumber || 0),
        });
      }
    });

    return mealsPrices;
  };

  for (let a = 1; a <= adults; a++) {
    for (let c = 0; c <= children; c++) {
      const adultText = a === 1 ? "1 adult" : `${a} adults`;
      const childText = c === 1 ? "1 child" : `${c} children`;
      if (c === 0) {
        combinations.push({
          adults: a,
          children: 0,
          title: `${adultText}`,
          price: getPrice(a),
          mealsPrices: getMealPrice(a, c),
        });
      } else {
        combinations.push({
          adults: a,
          children: c,
          title: `${adultText} ${childText}`,
          price: getPrice(a, c),
          mealsPrices: getMealPrice(a, c),
        });
      }
    }
  }

  return combinations;
}

export function convertObjectToArray(obj: any): any[] {
  return Object.keys(obj).map((key) => ({
    label: key,
    value: obj[key],
  }));
}

export function splitDateRangeIntoPeriods(
  start: string,
  end: string,
  periodDays = 4,
) {
  const startDate = moment(start);
  const endDate = moment(end);

  const periods = [];
  let currentDate = startDate.clone();

  while (currentDate <= endDate) {
    const periodEnd = currentDate.clone().add(periodDays, "days");
    const formattedPeriodStart = currentDate.format("DD/MM/YYYY");
    const formattedPeriodEnd = periodEnd.isAfter(endDate)
      ? endDate.format("DD/MM/YYYY")
      : periodEnd.format("DD/MM/YYYY");
    if (currentDate >= moment().add(7, "days")) {
      periods.push({
        label: `${formattedPeriodStart} - ${formattedPeriodEnd}`,
        value: currentDate.format("YYYY-MM-DD"),
      });
    }

    currentDate = periodEnd;
  }

  return periods;
}

export function utf8ToBase64(str: string) {
  // Encode the string as UTF-8
  const utf8EncodedString = encodeURIComponent(str);
  // Convert the UTF-8 encoded string to Base64
  const base64EncodedString = btoa(utf8EncodedString);
  return base64EncodedString;
}

export function calculateDiscountedAmount(
  totalAmount: number,
  discountPercentage: number,
) {
  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error("Invalid discount percentage. Must be between 0 and 100.");
  }
  const discountAmount = (totalAmount * discountPercentage) / 100;
  const finalAmount = totalAmount - discountAmount;
  return Math.ceil(finalAmount);
}
