import styled from "styled-components";
export const HomePageWrapper = styled.div`
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .scroll-full {
    overflow-y: auto;
    height: 100%;
  }
  .heading {
    color: #fff;
    width: 100%;
    text-align: center;
    padding-top: 40px;
  }
  .standard-home-container {
    width: 100%;
    position: relative;
    .standard-full {
      position: relative;
    }
    .bg-blue {
      color: #c0e0eb;
    }
    .bg-hotel {
      background: url(/assets/images/Background-Poupas-for-Holidays.jpg)
        top/cover fixed;
      opacity: 0.9;
      @media (max-width: 600px) {
        background: #37a7cd;
        opacity: 1;
      }
    }
    .bgimg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden !important;
      z-index: -10;
    }
    .flights-search-header {
      font-size: 21px;
      text-align: center;
      margin: 30px;
      font-family: Montserrat, sans-serif, "Open Sans";
      font-weight: 700;
      .or-color {
        color: #ffc926;
      }
    }
    .space-footer {
      height: 100px;
    }
    .full-height {
      position: relative;
      z-index: 0;
      video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        transform: translateX(-50%) translateY(-50%);
        background: url("/asset/images/Background-Poupas-for-Holidays.jpg")
          no-repeat;
        background-size: cover;
        transition: 1s opacity;
        @media (max-width: $screen-md) {
          display: none;
        }
      }
    }
  }
  .section {
    text-align: center;
    width: 100%;
    .pad-small {
      padding-top: 60px;
    }
    .section-heading {
      padding-bottom: 30px;
      max-width: 700px;
      margin: auto;
      h2 {
        font-size: 3.1em;
        font-weight: 700;
        /* color: #212121;
        padding-bottom: 2%; */
        font-family: Montserrat, sans-serif, "Open Sans";
      }
      .small {
        font-size: 14px;
      }
      span {
        display: block;
      }
    }
    &-prices {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    &-gray {
      background-color: #f4f4f4;
    }
  }
  .blue-color {
    color: #198dcc;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem;
    font-size: 28px;
    padding: 0;
    font-weight: 100;
  }
  .pad-bottom {
    padding-bottom: 2%;
  }
  .pad-top {
    padding-top: 2%;
  }
  .section-gray {
    background-color: #f4f4f4;
  }
  .container {
    margin: 0 auto;
    max-width: 940px;
    width: 100%;
  }
  .footer {
    text-align: center;
    padding: 20px;
  }
`;

export const MyGalleryWrapper = styled.div`
  width: 100%;
  margin: auto;
  max-width: 820px;
  img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  .img-item {
    position: relative;
    display: block;
    float: left;
    margin: 0 1% 1% 0;
    width: 24%;
    &:hover {
      &:after {
        width: 100%;
        background: rgba(0, 107, 183, 0.42);
      }
    }
    &:after {
      //background-color: darken($panel-default-heading-bg, 2%);
      content: "";
      display: block;
      margin: auto;
      //height: 3px;
      width: 0px;
      top: 0;
      position: absolute;
      width: 0px;
      bottom: 0;
      background: transparent;
      transition:
        width 0.35s ease,
        background-color 0.35s ease;
    }
  }
  figcaption {
    display: none;
  }
  .img-col-12 {
    img {
      width: 100%;
      height: auto;
    }
    width: 100%;
  }
  .img-col-8 {
    img {
      width: 100%;
      height: 360px;
    }
    width: 68%;
    height: 360px;
  }
  .img-col-5 {
    img {
      width: 100%;
      height: 259px;
    }
    width: 49%;
    height: 259px;
  }
  .img-col-4 {
    img {
      width: 100%;
      height: 175px;
    }
    width: 30%;
    height: 175px;
  }
  .image-caption {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    display: table;
    width: 100%;
    height: 100%;
    font-family: $font-family-sans-serif-title;
    .title {
      color: #fff;
      display: table-cell;
      vertical-align: middle;
      font-size: 36px;
      font-weight: 700;
      text-shadow: 0px 1px #000;
      .price {
        font-size: 19px;
        background-color: rgba(0, 0, 0, 0.5);
        width: 119px;
        padding: 2px 10px;
        margin: auto;
        border-radius: 5px;
      }
    }

    img {
      width: 50%;
      height: 50%;
      margin-bottom: 10px;
    }
  }
  @media (max-width: $screen-xs) {
    img {
      width: 100%;
      height: auto;
    }
    .img-item {
      margin: 0 1% 1% 0;
      width: 49%;
    }
    .main-image {
      img {
        width: 99%;
        height: auto;
      }
      width: 100%;
    }
  }
`;

export const PriceDetailsWrapper = styled.div`
  text-align: left;
  border: 1px solid #f4f4f4;
  padding: 0px;
  margin: 5px;
  .top-title {
    padding: 20px;
    .title {
      font-size: 21px;
      color: #388ba6;
      font-weight: 700;
      margin-bottom: 3px;
      font-family: $font-family-sans-serif-title;
    }
    p {
      font-size: 10px;
    }
    .small {
      font-size: 13px;
      color: #35a7cc;
    }
  }
  .destination-list {
    position: relative;
    margin: 0px 0px;
    padding: 0;
    list-style: none;
    line-height: 20px;
    li {
      display: block;
      border-bottom: 1px solid #eee;
      a {
        color: #8e9499;
        min-height: 60px;
        line-height: 20px;
        display: flex;
        padding: 20px;
        transition: all 0.4s ease-in-out;
        .name {
          color: #ff990e;
          font-weight: 700;
          font-size: 18px;
          flex: 1;
        }
        .price {
          color: #34a6cc;
          font-size: 26px;
          transition: all 0.4s ease;
        }
        .price-form {
          font-size: 11px;
          margin-right: 10px;
        }
        i {
          padding-right: 10px;
          font-size: 20px;
        }
        &:hover {
          background-color: #f8f8f8;
          .price {
            font-size: 30px;
            transition: all 0.4s ease;
          }
          transition: all 0.4s ease-in-out;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

export const HotelSearchWrapper = styled.div`
  &#hotels-search {
    .hcsb_container {
      width: 500px;
      margin: auto;
      text-align: left;
      font-size: 16px;
      background-color: rgba(15, 91, 153, 0.6);
      padding: 20px;
      border-radius: 0;
      .hcsb_outer {
        .hcsb_topTitle {
          display: none;
        }
        .hcsb_content {
          background-color: transparent;
          border: none;
          fieldset {
            background-color: transparent;
            .hcsb_citySearchWrapper {
              position: relative;
              margin-bottom: 20px;
              background-color: #fff;
              padding: 7px 20px 0px;
              box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
            }
            input {
              background-color: transparent;
              border: none;
              border-radius: 0;
              outline: 0;
              height: 40px;
              width: 100%;
              font-size: 16px;
              margin: 20px 0px 2px;
              padding: 0;
              box-shadow: none;
              box-sizing: content-box;
              transition: all 0.3s;
              &[type="checkbox"] {
                margin: 0px;
                width: auto;
              }
            }
            select {
              border-radius: 0;
              outline: 0;
              height: 40px;
              width: 200%;
              font-size: 15px;
              margin: 20px 0px 2px;
              padding: 0;
              box-shadow: none;
              box-sizing: content-box;
              transition: all 0.3s;
              background-color: transparent;
              border: 0;
            }
            .hcsb_fieldError {
              border: none !important;
              border-bottom: 1px solid #c00 !important;
              background-color: rgba(204, 0, 0, 0.08) !important;
            }
            .hcsb_checkoutDateWrapper {
              width: 50%;
              //float: left;
              padding-left: 1px;
              position: relative;
              display: inline-block;
              .hcsb_checkoutDateContent {
                float: right;
                display: inline-block;
                position: relative;
                margin-bottom: 20px;
                background-color: #fff;
                padding: 7px 9px 0px;
                -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
                box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
                width: 100%;
                .hcsb_checkoutMonth {
                  padding: 0;
                  width: 49%;
                  float: left;
                  margin-top: 20px;
                }
                .hcsb_checkoutDay {
                  padding: 0;
                  width: 49%;
                  float: left;
                  margin-top: 20px;
                }
              }
            }
            .hcsb_checkinDateWrapper {
              width: 50%;
              float: left;
              padding-right: 1px;
              position: relative;
              display: inline-block;
              .hcsb_checkinDateContent {
                float: right;
                display: inline-block;
                position: relative;
                margin-bottom: 20px;
                background-color: #fff;
                padding: 7px 9px 0px;
                -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
                box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
                width: 100%;
                .hcsb_checkinMonth {
                  padding: 0;
                  width: 49%;
                  float: left;
                  margin-top: 20px;
                }
                .hcsb_checkinDay {
                  padding: 0;
                  width: 49%;
                  float: left;
                  margin-top: 20px;
                }
              }
            }
            .hcsb_noDatesWrapper {
              padding: 0;
              height: 50px;
              label {
                color: #ffffff;
                font-size: 15px;
                padding-left: 30px;
                text-transform: none;
                font-family: arial, verdana, helvetica, sans-serif;
                &:before {
                  border: 2px solid #ffffff;
                  top: -1px;
                  margin-top: 0px;
                }
              }
              [type="checkbox"]:checked + label:before {
                border-right: 2px solid #ffffff;
                border-bottom: 2px solid #ffffff;
                border-top: 2px solid transparent;
                border-left: 2px solid transparent;
              }
            }
            .hcsb_guestsRoomsWrapper {
              float: right;
              display: inline-block;
              position: relative;
              margin-bottom: 20px;
              background-color: #fff;
              padding: 7px 9px 0px;
              -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
              box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
              width: 100%;
            }
            .hcsb_searchButton {
              border-radius: 3px;
              background: transparent;
              text-decoration: none;
              padding: 10px 12px;
              font-size: 18px;
              line-height: 21px;
              width: 100%;
              border: 1px solid #8db906;
              background-color: #8db906;
              color: #fff !important;
              margin: 6px 0px;
              font-weight: 700;
              //font-family: "Open Sans", sans-serif;
              transition: all 0.4s ease-in-out;
              &:hover {
                opacity: 1;
                background-color: darken(#8db906, 10%);
                border-color: darken(#8db906, 10%);
                transition: all 0.4s ease-in-out;
              }
            }
            .hcsb_checkinDateContent,
            .hcsb_checkoutDateContent {
              img {
                background: url("/assets/images/cal_icon.jpg") no-repeat scroll
                  0 0 transparent;
                height: 14px;
                width: 14px;
              }
            }
            label {
              position: absolute;
              top: 8px;
              z-index: 1;
              left: 13px;
              text-transform: uppercase;
              color: #212121;
              font-family: arial, verdana, helvetica, sans-serif;
            }
            img {
              position: absolute;
              right: 12px;
            }
          }
        }
      }
      @media (max-width: 500px) {
        width: 100%;
        /* background-color: #f8f8f8; */
      }
    }
    input {
      margin: 0;
      border-bottom: none;
      height: 25px;
      color: #212121;
      margin-bottom: 10px;
    }
    label {
      color: #212121;
      font-family: arial, verdana, helvetica, sans-serif;
    }
    .row {
      margin: 0px -10px;
    }
    .body {
      background-color: rgba(15, 91, 153, 0.6);
      padding: 20px;
      .trip-type {
        label {
          color: #ffffff;
          &:before {
            border: 2px solid #ffffff;
          }
          &:after {
            border: 2px solid #ffffff;
            background-color: #ffffff;
          }
        }
      }
      .location-search {
        position: relative;
        .departure {
          border-bottom: 1px solid #eee;
        }
        .destination {
        }
        .reverse-btn {
          position: absolute;
          color: #00a3d9;
          top: 25px;
          right: 3px;
          background-color: #fff;
          padding: 30px;
          transform: rotate(90deg);
          cursor: pointer;
          @media (max-width: $screen-xs) {
            top: 46px;
            right: 3px;
            padding: 10px;
          }
        }
      }
      .date {
        .depart {
        }
        .return {
        }
        .disable-return {
          background-color: #e0e0e0;
          opacity: 0.7;
        }
        .date-icon {
          position: absolute;
          color: #ccc;
          top: 8px;
          right: 15px;
          font-size: 14px;
        }
      }
      .passenger-info {
      }
      .input-box {
        position: relative;
        margin-bottom: 20px;
        background-color: #fff;
        padding: 7px 20px 0px;
        @include box-shadow(0 8px 6px -6px rgba(0, 0, 0, 0.2));
      }
      .search-btn {
      }
    }
    .footer {
    }
    @media (max-width: 500px) {
      width: 100%;
      .header {
        font-size: 21px;
        text-align: left;
        margin: 10px;
      }
      .body {
        background-color: #f4f4f4;
        .trip-type {
          label {
            color: #212121;
            &:before {
              border: 2px solid #212121;
            }
            &:after {
              border: 2px solid #00a3d9;
              background-color: #00a3d9;
            }
          }
        }
      }
    }
    .picker__today {
      position: absolute;
      top: 10px;
      left: 0px;
      color: #fff;
    }
    .picker__close {
      position: absolute;
      top: 10px;
      right: 0px;
      color: #fff;
    }
    .picker__footer {
      display: block;
    }

    &.left-saerch {
      .hcsb_container {
        background-color: rgb(21, 132, 174) !important;
        width: 100% !important;
        padding: 25px 0px !important;
      }
      .hcsb_outer {
        padding: 0 5px 0px !important;
      }
    }
  }
  .flights-search-header {
    font-size: 21px;
    text-align: center;
    margin: 30px;
    font-family: arial, verdana, helvetica, sans-serif;
    font-weight: 700;
    .or-color {
      color: #ffc926;
    }
  }

  .flights-search-left {
    width: 100% !important;
    .body {
      background-color: transparent !important;
      .trip-type {
        li {
          display: inline-block;
          margin-right: 20px;
        }
        label {
          color: #212121 !important;
          &:before {
            border: 2px solid #212121 !important;
          }
          &:after {
            border: 2px solid #00a3d9 !important;
            background-color: #00a3d9 !important;
          }
        }
      }
    }
  }

  .hcsb_extend {
    background-color: rgba(13, 70, 115, 0.26) !important;
    //width: 100% !important;
    padding: 20px 24px 25px !important;
    //margin-left: -20px !important;
    #EX-SB_8ec3f8eb78_inner {
      background-color: #f8f8f8 !important;
      border-radius: 3px !important;
    }
    .hcsb_roomsConfig {
      background-color: #f8f8f8 !important;
      border-bottom: 1px solid #ddd !important;
      fieldset {
        background-color: #f8f8f8 !important;
        border-bottom: 1px solid #ddd !important;
      }
    }
  }

  #hcsb_bodyElements {
    font:
      13px normal arial,
      helvetica,
      sans-serif !important;
    .ui-widget-header {
      background-color: #427ead !important;
      height: 30px !important;
    }
    .ui-datepicker-month {
      display: block !important;
      border-color: #427ead !important;
      background-color: #427ead !important;
      height: 20px !important;
      width: 75% !important;
      margin: 3px auto !important;
    }
  }
`;

export const ThingsToDohWrapper = styled.div`
  #viatorWidget {
    width: auto !important;
    .widget_header {
      display: none;
    }
    .widget_body {
      background-color: #ffffff !important;
      font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    }
    .widget_entry {
      padding: 5px 0 20px;
      display: block;
      min-height: 155px;
      border-bottom: 1px solid #eee;
      margin-bottom: 10px;
      .widget_product_title {
        color: #2682a0;
        font-size: 13px;
      }
      .price_format {
        margin-bottom: 5px;
        .price {
          float: left;
          font-weight: bold;
          font-size: 14px;
          padding-right: 5px;
          color: #00a3d9;
        }
      }
      ul {
        font-size: 11px;
        list-style: none;
        margin: 0;
        padding: 0 7px 0;
        float: left;
        width: 66%;
        .widget_more_info {
          float: right;
          color: #00759a;
        }
      }
    }
  }

  .ViatorFindTTD {
    width: 500px !important;
    margin: auto;
    @media (max-width: 500px) {
      width: 100% !important;
    }
    > .pas {
      background-color: rgba(15, 91, 153, 0.6) !important;
      padding: 35px 20px !important;
      border-radius: 0 !important;
      border: none !important;
      .module_header {
        display: none;
      }
      .module_body {
        background-color: transparent !important;
        font-family: $font-family-sans-serif-title !important;
        .pas_destinations {
          position: relative;
          margin-bottom: 20px;
          background-color: #fff;
          padding: 7px 20px 3px;
          box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
          #pascountry {
            border-bottom: 1px solid #a7a7a7;
          }
        }
        .pas_search_option {
          display: flex;
          align-items: center;
          line-height: 21px;
          gap: 20px;
          .checkbox {
            margin-bottom: 0;
          }
        }
        .pas_activities {
          .dd {
            display: inline;
          }
        }
        .btn {
          float: none !important;
          box-shadow: none;
          background-color: transparent !important;
          width: 100%;
          padding: 0;
          button {
            height: auto;
            border-radius: 3px;
            background: transparent;
            text-decoration: none;
            padding: 15px 12px;
            font-size: 14px;
            line-height: 21px;
            width: 100%;
            border: 1px solid #8db906;
            background-color: #8db906;
            color: #fff !important;
            margin: 6px 0px;
            transition: all 0.4s ease-in-out;
            box-shadow:
              0 2px 5px 0 rgba(0, 0, 0, 0.16),
              0 2px 10px 0 rgba(0, 0, 0, 0.12);
            &:hover {
              opacity: 1;
              background-color: darken(#8db906, 10%);
              border-color: darken(#8db906, 10%);
              transition: all 0.4s ease-in-out;
            }
          }
          &:hover {
            box-shadow: none;
            background-color: transparent !important;
          }
        }
      }
      select,
      input {
        margin: 0;
        border-bottom: none;
        height: 40px;
        color: #212121;
        margin-bottom: 10px;
        //min-width: 200px;
        display: block;
        background-color: #fff !important;
        -webkit-appearance: menulist !important;
        border-radius: 0 !important;
      }
      label {
        &:before {
          border: 2px solid #ffffff;
        }
      }
      [type="checkbox"]:checked + label:before {
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
      }
    }
  }
`;
