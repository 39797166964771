import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../atoms";
import { CartTotalBtnWrapper } from "./Cart.style";
interface ICartTotalBtnProps {
  submit?: () => void;
  finalPrice: number;
  loading: boolean;
  checkInCheckOut: string;
  onRequest: boolean;
}
export const CartHotelTotalBtn: React.FC<ICartTotalBtnProps> = ({
  submit,
  finalPrice,
  loading,
  checkInCheckOut,
  onRequest,
}) => (
  <CartTotalBtnWrapper>
    <div className="cart-total-date">{checkInCheckOut}</div>
    <div className="cart-total-total">
      <span>
        {" "}
        <FormattedMessage
          description="Total"
          defaultMessage="Total"
          id="XmkR3j"
        />
      </span>
      <span className="cart-total-total-price">€{finalPrice}</span>
    </div>
    {onRequest && (
      <div
        style={{
          textAlign: "center",
          padding: "10px 20px",
          fontSize: 12,
          color: "red",
        }}
      >
        On Request: Δυστυχώς το δωμάτιο αυτό φαίνεται πως έχει εξαντληθεί για τη
        συγκεκριμένη περίοδο. Μπορείτε παρόλα αυτά να ολοκληρώσετε τη κράτηση
        χωρίς απολύτως καμία χρέωση και θα λάβετε απάντηση για διαθεσιμότητα
        εντός 24 εργάσιμων ωρών. Εναλλακτικά μπορείτε να επιλέξετε νέες
        ημερομηνίες.
      </div>
    )}
    {submit && (
      <div style={{ padding: "10px 20px 20px" }}>
        <Button className="cart-total-btn" onClick={submit} loading={loading}>
          {" "}
          {onRequest ? (
            "Αίτημα κράτησης"
          ) : (
            <FormattedMessage
              description="Book Now"
              defaultMessage="Book Now"
              id="1ZQWdS"
            />
          )}
        </Button>
      </div>
    )}
  </CartTotalBtnWrapper>
);
