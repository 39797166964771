/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Divider, notification, Space } from "../../atoms";
import { Logo } from "../../atoms/Logo/Logo";
import { BurgerMenuBtn, LeftMenu } from "../../molecules";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { listenToSelectedCustomer14860 } from "../../../redux/data/customer14860/customer14860Actions";
import { HeaderWrapper } from "./Header.style";

const logoUrl = process.env.REACT_APP_SITE_LOGO;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const partnerId = process.env.REACT_APP_SITE_ID!;

const partnerLoginForm: any = {
  POED: "LoginFormPoed",
  PASYDY: "LoginForm",
};
interface IHeaderProps {
  isMobile: boolean;
  setShowMenu: (show: boolean) => void;
  showMenu: boolean;
}
export const Header: React.FC<IHeaderProps> = ({
  isMobile,
  setShowMenu,
  showMenu,
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const { selectedCustomer14860 } = useSelector(
    (state: any) => state.customers,
  );
  const [isPasydy, setIsPasydy] = React.useState(false);
  const [isAuth, setIsAuth] = React.useState(false);
  const handleShowMobileMenu = (e: any) => {
    setShowMenu(e.target.checked);
  };
  console.log(partnerLoginForm[partnerId]);
  const handleLogin = () => {
    dispatch(
      openModal({
        modalType: partnerLoginForm[partnerId] || "LoginForm",
        modalProps: { title: "Σύνδεση" },
      }),
    );
    // setAuthenticated(true);
    // history.push("/admin/");
  };
  const handleLogout = async () => {
    try {
      localStorage.removeItem("userId");
      dispatch(listenToSelectedCustomer14860(null as any));
      history("/");
    } catch (e) {
      notification.error({
        message: "Αποτυχημένη σύνδεση",
        description: "Αποτυχημένη σύνδεση",
      });
    }
  };
  React.useEffect(() => {
    if (
      selectedPackage?.partnerId === "PASYDY" ||
      location.pathname.includes("pasydy")
    ) {
      setIsPasydy(true);
    } else {
      setIsPasydy(false);
    }
  }, [location, selectedPackage]);
  React.useEffect(() => {
    if (selectedCustomer14860) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [selectedCustomer14860]);
  return (
    <HeaderWrapper>
      <Logo />

      <div className="header-pasydy-logo-name">
        για
        <img className="header-pasydy-logo" src={logoUrl} alt="" />
      </div>

      {!isMobile && (
        <Space style={{ display: "flex" }} className="header-menu">
          <Link to={"/"}>
            <FormattedMessage
              description="Packages"
              defaultMessage="Packages"
              id="46PJ2T"
            />
          </Link>
          {!isPasydy && (
            <>
              {" "}
              <Divider type="vertical" />
              <Link to="/hotels">Ξενοδοχεία Κύπρου</Link>
              <Divider type="vertical" />
            </>
          )}
        </Space>
      )}
      {!isMobile && (
        <div className="header-phone-number">
          {isPasydy ? (
            <a href="mailto:pasydy@poupas.com.cy">
              <MailOutlined className="header-phone-number-icon" />
            </a>
          ) : (
            <a href="mailto:reservations@poupas.com.cy">
              <MailOutlined className="header-phone-number-icon" />
            </a>
          )}
          <PhoneOutlined className="header-phone-number-icon" />
          <div className="header-phone-number-text">
            <div className="header-phone-number-first">7008 7003</div>
            <div>+35722252508</div>
          </div>
        </div>
      )}
      {!isMobile && (
        <LeftMenu
          authenticated={isAuth}
          login={handleLogin}
          logout={handleLogout}
          currentUser={selectedCustomer14860}
        />
      )}
      {isMobile && (
        <div className="header-mobile-icon">
          <BurgerMenuBtn
            onClick={handleShowMobileMenu}
            checked={showMenu}
          ></BurgerMenuBtn>
        </div>
      )}
    </HeaderWrapper>
  );
};
