import React from "react";
import { Link } from "react-router-dom";
import { LogoWrapper } from "./Logo.style";

export const Logo: React.FC = () => (
  <LogoWrapper>
    <Link to="/">
      <img src="/assets/poupas_logo_w.svg" alt="" />
    </Link>
  </LogoWrapper>
);
