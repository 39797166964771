import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_HOTEL28355 } from "./firebaseConstants";

export function fetchHotel28355FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_HOTEL28355);
  return collectionWithArgs(ref, args || []);
}
export function listenToHotel28355FromFirestore(id: string) {
  return db.collection(COLLECTION_HOTEL28355).doc(id);
}
