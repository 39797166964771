import React from "react";
import { ISelectOption } from "../../../common/types";
import { Form, Checkbox } from "../../atoms";
interface IInputRadioFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  options: ISelectOption[];
  disabled?: boolean;
  optionType?: any;
  onChange?: (event: any) => void;
}
const CheckboxGroup = Checkbox.Group;
export const InputCheckBoxField: React.FC<IInputRadioFieldProps> = ({
  label,
  rules,
  name,
  style,
  className,
  initialValue,
  bordered = true,
  options,
  disabled,
  onChange,
  optionType,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
  >
    <CheckboxGroup
      style={style}
      options={options}
      onChange={onChange}
      disabled={disabled}
    />
  </Form.Item>
);
