import styled from "styled-components";
export const HotelInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  .hotel-info {
    &-img {
      padding-right: 10px;
      img {
        height: 64px;
        width: 64px;
        border-radius: 4px;
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
    }
    &-rate {
      font-size: 11px;
    }
    &-name {
      font-size: 16px;
      color: #005fc3;
      font-weight: 900;
      font-family: "avenir";
      &-meal {
        color: #0eb381;
        font-weight: normal;
      }
    }
    &-location {
      color: #313e55;
      font-size: 13px;
    }
    &-more-details {
      color: #005fc3;
      font-size: 12px;
      cursor: pointer;
    }
  }
`;
export const HotelFullInfoWrapper = styled.div`
  margin: 20px;
  .hotel-full-info {
    &-details {
      display: flex;
      flex-direction: column;
    }
    &-images {
      background-color: #eee;
      padding: 10px;
    }
    &-rate {
      font-size: 11px;
    }
    &-name {
      font-size: 18px;
      color: #005fc3;
      font-weight: 900;
      font-family: "avenir";
      &-meal {
        color: #0eb381;
        font-weight: normal;
      }
    }
    &-location {
      color: #313e55;
      font-size: 13px;
    }
    &-more-details {
      color: #005fc3;
      font-size: 12px;
      cursor: pointer;
    }
  }
`;
export const HotelItemWrapper = styled.div`
  display: grid;
  gap: 10px;
  margin-top: 24px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  padding: 15px 15px;
  grid-template-columns: 300px 1fr;
  @media only screen and (max-width: 600px) {
    max-width: 400px;
    width: 100%;
    margin: 20px 0px;
    padding: 10px;
  }
  .hotel-item {
    &-hotel {
      display: flex;
      gap: 25px;
      margin-bottom: 10px;
      padding: 0 10px;
    }
    &-body {
      width: 100%;
      overflow: hidden;
    }
    &-select {
      flex: 1;
      text-align: right;
      padding: 10px;
      button {
        background-color: #0027ca;
        color: #fff;
        width: 120px;
        height: 32px;
        font-size: 14px;
        @media (max-width: 1200px) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    &-prices {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 10px;
      @media only screen and (max-width: 600px) {
        padding: 10px;
      }
    }
    &-is-selected {
      background-color: #00ca54 !important;
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
    padding: 0;
  }
`;
export const HotelFilterWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  .package-filter {
    &-header {
      color: #005fc3;
      font-size: 18px;
      font-weight: 700;
      padding: 15px;
      font-family: "Roboto";
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-body {
      flex: 1;
      position: absolute;
      top: 50px;
      right: 0;
      left: 0;
      bottom: 0;
    }
    &-form {
      border: 1px solid #dbe4eb;
      overflow-y: auto;
      height: 100%;
    }
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .package-filter-collapse {
    background-color: #fff;
    .ant-collapse-header {
      color: #0c3d71;
      font-size: 14px;
      font-weight: 700;
      font-family: "Avenir";
    }
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    &-item {
      font-size: 14px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #f9f9f9;
    }
  }
`;

export const HotelSearchAvailabilityWrapper = styled.div`
  background-color: #eee;
  margin: 10px 20px;
  padding: 10px 20px;
  .hotelSearchAvailability {
    &-fields {
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 500px;
      .ant-space {
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .ant-form-item {
        margin-bottom: 7px;
      }
    }
  }
`;

export const HotelPriceListWrapper = styled.div`
  margin: 10px 20px;
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #868686;
  }
  .hotelPriceList {
    &-fields {
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 500px;
      .ant-form-item {
        margin-bottom: 7px;
      }
    }
    &-price {
      display: flex;
      flex-direction: column;
      &-no-discount {
        color: red;
        text-decoration: line-through;
      }
    }
    &-roomCol {
      align-content: flex-start;
    }
  }
`;
export const MealPriceSelectionWrapper = styled.div`
  .mealPriceSelection {
    &-include {
      color: green;
    }
    &-policy {
      margin-top: 5px;
      color: green;
      font-size: 12px;
    }
    &-payment {
      margin-top: 10px;
      color: #38a7cd;
      font-size: 12px;
      font-weight: 700;
    }
  }
`;

export const HotelCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .HotelCart {
    &-title {
      color: #005fc3;
      font-size: 18px;
      font-weight: 700;
      padding: 15px;
      font-family: "Roboto";
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #eee;
    }
    &-body {
      padding: 0 15px;
    }
    &-noRoom {
      font-size: 18px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      padding: 20px;
      color: #aaaaaa;
      margin-top: 50px;
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;
export const HotelRoomCartItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  background-color: #fff;
  position: relative;
  .HotelRoomCartItem {
    &-roomNumber {
      font-size: 14px;
      padding-right: 5px;
    }
    &-name {
      font-size: 13px;
      width: 150px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-price {
      font-size: 16px;
    }
    &-mealType {
      font-size: 13px;
      color: #333;
      margin-top: 6px;
      font-weight: 400;
    }
    &-refundable {
      font-size: 13px;
      color: #2472ca;
    }
    &-guest {
      font-size: 14px;
      &-child {
        font-size: 11px;
      }
    }
    &-remove {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #eb5a5a;
    }
  }
`;
export const PeriodBoxWrapper = styled.div`
  width: 160px;
  height: 125px;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 6px 0px;
  &:hover {
    background-color: #eee;
  }
  .PeriodBox {
    &-title {
      background-color: #0c3d71;
      color: #fff;
      font-size: 13px;
      line-height: 28px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-body {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    &-des {
      color: #5f6e87;
      font-family: "Avenir";
    }
    &-mine {
      color: rgb(56 167 205);
      font-weight: 700;
    }
    &-select {
      background-color: #4caf50;
      border-color: #4caf50;
      color: rgb(255, 255, 255);
      text-align: center;
      border-radius: 5px;
      font-size: 13px;
      height: 28px;
      margin: 0px 7px 7px;
      line-height: 13px;
    }
  }
`;

export const HotelRoomInfoWrapper = styled.div`
  width: 300px;
  .HotelRoomInfo {
    &-title {
      color: #005fc3;
      font-size: 16px;
      font-weight: 700;
      padding: 10px 0 0px;
      font-family: "Roboto";
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-squareMeters {
      font-size: 14px;
      color: #5f6e87;
      font-family: "Avenir";
    }
    &-amenities {
      border-top: 1px solid #eee;
      padding-top: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      @media only screen and (max-width: 600px) {
        display: none;
      }
      .ant-tag {
        display: block;
        background-color: #229cff13;
        color: #118fe9;
        border: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
