import React from "react";
import { Col, Row } from "antd";
import { InputDateField, InputField, SelectField } from "..";
import { BookingWrapper } from "./Booking.style";

interface IProps {
  form: any;
}
const partnerNameGr = process.env.REACT_APP_PARTNER_NAME_GR;

export const BookingCustomerInput: React.FC<IProps> = ({ form }) => {
  const compareToFirstEmail = ({ getFieldValue }: any) => ({
    validator(_: any, value: string) {
      if (!value || getFieldValue(["customer", "emailAddress"]) === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("The two emails that you entered do not match!"),
      );
    },
  });

  return (
    <BookingWrapper>
      <div className="booking-container">
        <div className="booking-title">Στοιχεία μέλους της {partnerNameGr}</div>
        <Row gutter={24}>
          <Col span={8} xs={24} sm={12}>
            <SelectField
              label="Τίτλος"
              name={["customer", "title"]}
              rules={[
                {
                  required: true,
                  message: "Τίτλος",
                },
              ]}
              placeholder="Τίτλος"
              options={[
                { value: "MR", label: "Κος" },
                { value: "MS", label: "Κα" },
              ]}
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Όνομα"
              name={["customer", "name"]}
              rules={[
                {
                  required: true,
                  message: "Όνομα",
                },
              ]}
              placeholder="Όνομα"
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Επίθετο"
              name={["customer", "surname"]}
              rules={[
                {
                  required: true,
                  message: "Όνομα",
                },
              ]}
              placeholder="Επίθετο"
            />
          </Col>

          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Διεύθυνση Email"
              name={["customer", "emailAddress"]}
              rules={[
                {
                  type: "email",
                  message: "Διεύθυνση Email",
                },
                {
                  required: true,
                  message: "Διεύθυνση Email",
                },
              ]}
              placeholder="Διεύθυνση Email"
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Επιβεβαίωση διεύθυνσης Email"
              name="confirmEmailAddress"
              rules={[
                {
                  required: true,
                  message: "Επιβεβαίωση διεύθυνσης Email",
                },
                compareToFirstEmail(form),
              ]}
              placeholder="Επιβεβαίωση διεύθυνσης Email"
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Τηλέφωνο Επικοινωνίας"
              name={["customer", "phoneNumber"]}
              rules={[
                {
                  required: true,
                  message: "Τηλέφωνο Επικοινωνίας",
                },
              ]}
              placeholder="Τηλέφωνο Επικοινωνίας"
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Αριθμός Ταυτότητας"
              name={["customer", "idNumber"]}
              rules={[
                {
                  required: true,
                  message: "Αριθμός Ταυτότητας",
                },
              ]}
              placeholder="Αριθμός Ταυτότητας"
              help=""
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputDateField
              label="Ημ. Γεννήσεως"
              name={["customer", "dateOfBirth"]}
              rules={[
                {
                  required: true,
                  message: "Ημ. Γεννήσεως",
                },
              ]}
              style={{ width: "100%" }}
              placeholder="Ημ. Γεννήσεως"
            />
          </Col>
        </Row>{" "}
      </div>
    </BookingWrapper>
  );
};
