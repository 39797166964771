import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_CUSTOMER14860 } from "./firebaseConstants";

export function fetchCustomer14860FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_CUSTOMER14860);
  return collectionWithArgs(ref, args || []);
}
export function listenToCustomer14860FromFirestore(id: string) {
  return db.collection(COLLECTION_CUSTOMER14860).doc(id);
}

export async function checkUserInFirestore(idNumber: string) {
  try {
    const currentCustomer = await db
      .collection(COLLECTION_CUSTOMER14860)
      .doc(idNumber)
      .get();
    if (currentCustomer.exists) {
      sessionStorage.setItem("userId", idNumber);
      await db.collection(COLLECTION_CUSTOMER14860).doc(idNumber).update({
        lastLoginDate: new Date(),
      });
      return currentCustomer.data();
    }
    throw new Error("User do exist");
  } catch (err) {
    throw err;
  }
}

export async function checkUserPoedInFirestore(
  idNumber: string,
  phoneNumber: string,
) {
  try {
    const id = `${idNumber}-${phoneNumber}`;
    const currentCustomer = await db
      .collection(COLLECTION_CUSTOMER14860)
      .doc(id)
      .get();
    if (currentCustomer.exists) {
      sessionStorage.setItem("userId", id);
      const user: any = currentCustomer.data();
      await db.collection(COLLECTION_CUSTOMER14860).doc(id).update({
        lastLoginDate: new Date(),
      });
      return user;
    }
    throw new Error("User do exist");
  } catch (err) {
    throw err;
  }
}
