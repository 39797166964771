/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { PhoneOutlined } from "@ant-design/icons";
import { MobileMenuWrapper } from "./MobileMenu.style";
interface IMobileMenuProps {
  showMenu: boolean;
  setShowMenu: (e: boolean) => void;
}
export const MobileMenu: React.FC<IMobileMenuProps> = ({
  showMenu,
  setShowMenu,
}) => {
  const handleShowMobileMenu = () => {
    setShowMenu(false);
  };
  return (
    <MobileMenuWrapper showMenu={showMenu}>
      <div className="mobile-menu-title">
        <FormattedMessage
          description="Plan Your Holidays"
          defaultMessage="Plan Your Holidays"
          id="yCqdwg"
        />
      </div>
      <div className="mobile-menu-sub-title">
        <FormattedMessage
          description="I 'M SEARCHING FOR"
          defaultMessage="I 'M SEARCHING FOR"
          id="RHBL9W"
        />
      </div>
      <div className="mobile-menu-items">
        <Link
          to="/packages"
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          <FormattedMessage
            description="Packages"
            defaultMessage="Packages"
            id="46PJ2T"
          />
        </Link>
        <Link
          to="/hotels"
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          <FormattedMessage
            description="Hotels"
            defaultMessage="Hotels"
            id="qrGoSh"
          />
        </Link>
        <Link
          to="/things-to-do"
          className="mobile-menu-item"
          onClick={handleShowMobileMenu}
        >
          <FormattedMessage
            description="Things to do"
            defaultMessage="Things to do"
            id="cb2l99"
          />
        </Link>
      </div>
      <div className="mobile-menu-footer">
        <div className="mobile-menu-phone-number">
          <PhoneOutlined className="mobile-menu-phone-number-icon" />
          <div className="mobile-menu-phone-number-text">
            <div className="mobile-menu-phone-number-first">
              <a href="tel:70087003">7008 7003</a>
            </div>
            <div>
              <a href="tel:+35722252508">+35722252508</a>
            </div>
          </div>
        </div>
        {/* {!authenticated ? (
          <Button className="mobile-menu-btn" onClick={handleLogin}>
            {" "}
            <FormattedMessage
              description="Login"
              defaultMessage="Login"
              id="gdzUOH"
            />
          </Button>
        ) : (
          <Button className="mobile-menu-btn" onClick={handleLogout}>
            {" "}
            <FormattedMessage
              description="Logout"
              defaultMessage="Logout"
              id="LAaOhU"
            />
          </Button>
        )} */}
      </div>
    </MobileMenuWrapper>
  );
};
