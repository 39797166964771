import styled from "styled-components";
export const PackageListFrontEndWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0px;
  display: flex;
  flex-direction: column;

  .package-list-frontend {
    position: relative;
    flex: 1;
    &-body {
      display: flex;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
    &-left {
      overflow-y: hidden;
      width: 300px;
      background-color: #f8f8f8;
      transform: translateX(0);
      transition: all 0.35s ease;
      @media only screen and (max-width: 1200px) {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        bottom: 0;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    &-right {
      background-color: #f8f8f8;
      padding: 0px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &-header {
      background: #f3f3f3;
      height: 50px;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      &-filter {
        border-right: 1px solid #ffffff;
        button {
          font-size: 18px;
        }
      }
      &-sorting {
        flex: 1;
        text-align: right;
        padding-right: 20px;
        .title {
          font-size: 14px;
          color: #22335d;
          padding-right: 10px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          position: relative;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #d9d9d9;
          border-radius: 0px;
          width: 200px;
          text-align: left;
          @media (max-width: 440px) {
            width: 150px;
          }
        }
      }

      @media (min-width: 1200px) {
        padding-left: 20px;
      }
    }
    &-result {
      flex: 1;
      overflow-y: auto;
    }
  }
  .hide {
    transform: translateX(-100%);
    width: 0;
  }
`;

// theme is now fully typed
export const PackageSearchWrapper = styled.div`
  background-color: #eee;
  padding: 5px 20px;
  .ant-form-item {
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    margin: 5px 0px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0px;
  }
  .ant-form-item-label {
    padding: 0 0 5px;
    padding-left: 5px;
    text-transform: uppercase;
    font-size: 12px;
    color: #22335d;
  }
  .search-btn {
    width: 150px;
    background-color: #38af5c;
    color: #fff;
    font-size: 18px;
    height: 80px;
    padding: 0px;
    border-radius: 2px;
  }
`;
export const PackageFilterWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  .package-filter {
    &-header {
      color: #005fc3;
      font-size: 18px;
      font-weight: 700;
      padding: 15px;
      font-family: "Roboto";
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-body {
      flex: 1;
      position: absolute;
      top: 50px;
      right: 0;
      left: 0;
      bottom: 0;
      overflow-y: auto;
      border: 1px solid #dbe4eb;
      overflow-y: auto;
      height: 100%;
    }
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .package-filter-collapse {
    background-color: #fff;
    .ant-collapse-header {
      color: #0c3d71;
      font-size: 14px;
      font-weight: 700;
      font-family: "Avenir";
    }
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    &-item {
      font-size: 14px;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #f9f9f9;
    }
  }
`;

export const PackageItemWrapper = styled.div`
  margin: 20px 20px;
  padding: 0px;
  border-bottom: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  display: grid;
  grid-template-columns: 340px 1fr 200px;
  align-items: center;
  grid-gap: 10px;
  background-color: #ffffff;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    max-width: 400px;
    margin: 20px auto;
  }
`;
