import { Result } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHotel28355FromFirestore,
  fetchRoom40231FromFirestore,
} from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { ItemNotFound, Loader } from "../../molecules";
import { listenToBooking30512HotelFromFirestore } from "../../../firestore/firestoreService/Booking30512HotelStore";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { PackageBookingConfirmFrontendWrapper } from "../Package/PackageBookingConfirmFrontend/PackageBookingConfirmFrontend.style";
interface IPackageBProps {
  bookingId: string;
  onRequest?: boolean;
}
export const HotelBookingConfirm: React.FC<IPackageBProps> = ({
  bookingId,
  onRequest,
}) => {
  const dispatch = useDispatch();
  const [selectedBooking, setSelectedBooking] = React.useState<any>();
  const [, setHotels] = React.useState<any>();
  const [, setRooms] = React.useState<any>();
  const { loading, error } = useSelector((state: any) => state.async);

  userFirestoreDoc({
    query: () => listenToBooking30512HotelFromFirestore(bookingId),
    data: setSelectedBooking,
    shouldExecute: !!bookingId,
    deps: [dispatch, bookingId],
    local: true,
  });
  useFirestoreCollection({
    query: () => fetchRoom40231FromFirestore(selectedBooking?.hotelId, []),
    data: setRooms,
    shouldExecute: !!selectedBooking?.hotelId,
    deps: [dispatch, selectedBooking?.hotelId],
    local: true,
  });

  useFirestoreCollection({
    query: () =>
      fetchHotel28355FromFirestore([
        {
          type: "filter",
          name: "exclusiveOffer",
          opr: "==",
          value: true,
        },
      ]),
    data: setHotels,
    deps: [dispatch],
    local: true,
  });

  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/packages"
        redirectBtnText="Back to packages"
        itemType="Booking"
        itemId={bookingId}
      />
    );
  }
  return (
    <PackageBookingConfirmFrontendWrapper>
      <div className="booking-confirm-body">
        {onRequest ? (
          <Result
            status="success"
            title={" Ευχαριστούμε."}
            subTitle={"Έχουμε λάβει το αίτημα κράτησης"}
          />
        ) : (
          <Result
            status="success"
            title={" Η κράτηση σας ολοκληρώθηκε Επιτυχώς"}
            subTitle={
              <FormattedMessage
                description="An email with your booking details will arrive shortly"
                defaultMessage="An email with your booking details will arrive shortly"
                id="H5zQ4s"
              />
            }
          />
        )}

        {/* <div className="booking-confirm-total">
          <FormattedMessage
            description="Total Package Price Paid "
            defaultMessage="Total Package Price Paid"
            id="u+QVfG"
          />{" "}
          <span>
            €
            {selectedBooking?.initialPayment
              ? selectedBooking?.partialPayment
              : selectedBooking?.totalPayment}
          </span>
        </div>
        <div className="booking-confirm-booking">
          <div className="booking-confirm-message">
            <div className="booking-confirm-message-title">
              <FormattedMessage
                description="Dear "
                defaultMessage="Dear "
                id="rkLJUY"
              />{" "}
              {selectedBooking?.customer?.name}!
            </div>
            <div>
              <div className="booking-confirm-message-des">
                <FormattedMessage
                  description="Thank you for choose Poupas for Holidays for your next trip to"
                  defaultMessage="Thank you for choose Poupas for Holidays for your next trip to"
                  id="tw9E0L"
                />{" "}
                {find(hotels, ["id", selectedBooking?.hotelId])?.name}
              </div>

              <div className="booking-confirm-message-des">
                Πιο κάτω θα δείτε επίσης όλα τα στοιχεία της κράτησης σας ώστε
                να επιβεβαιώσετε πως είναι σωστά και να αποφύγετε επιπλέον
                πληρωμές για διόρθωση τους όπως ονόματα κ.λ.π.
              </div>
              <Divider>Details</Divider>
              <div>
                <h2>Booking Details</h2>

                <p>
                  <strong>Hotel:</strong>{" "}
                  {find(hotels, ["id", selectedBooking?.hotelId])?.name}
                  {}
                </p>
                <p>
                  <strong>Booking ID:</strong> {selectedBooking?.bookingId}
                </p>
                <p>
                  <strong>Check-In:</strong> {selectedBooking?.checkIn.trim()}
                </p>
                <p>
                  <strong>Check-Out:</strong> {selectedBooking?.checkOut.trim()}
                </p>

                <h3>Rooms</h3>
                {selectedBooking?.rooms.map((room: any, index: number) => (
                  <div key={index}>
                    <h4>Room {index + 1}</h4>
                    <p>
                      <strong>Room:</strong>{" "}
                      {find(rooms, ["id", room.roomId])?.name}
                    </p>
                    <p>
                      <strong>Room Number:</strong> {room.roomNumber}
                    </p>
                    <p>
                      <strong>Adults:</strong> {room.adultNumber}
                    </p>
                    <p>
                      <strong>Children:</strong> {room.childNumber}
                    </p>
                    <p>
                      <strong>Infants:</strong> {room.infantNumber}
                    </p>
                    <p>
                      <strong>Meal Type:</strong> {room.mealType}
                    </p>
                    <p>
                      <strong>Total Price:</strong> {room.totalPrice}
                    </p>
                    <p>
                      <strong>Cancelation Policy:</strong>{" "}
                      {room.cancelationPolicy}
                    </p>
                    <p>
                      <strong>Refundable Date:</strong> {room.refundableDate}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </PackageBookingConfirmFrontendWrapper>
  );
};
