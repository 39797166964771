/* eslint-disable no-console */
// import { httpsCallable } from "firebase/functions";
// import { functions } from "../../config/firebase";
import firebase from "../../config/firebase";
import { IHotelBookingInput } from "../../common/types";
import { db } from "./firestoreService";
import {
  COLLECTION_BOOKING30512_HOTEL,
  COLLECTION_EXCLUSIVEHOTEL64774,
  COLLECTION_HOTEL28355,
} from "./firebaseConstants";

export function listenToBooking30512HotelFromFirestore(id: string) {
  return db.collection(COLLECTION_BOOKING30512_HOTEL).doc(id);
}
export async function addBooking30512HotelToFirestore(doc: IHotelBookingInput) {
  try {
    const currentBookings = await db
      .collection(COLLECTION_BOOKING30512_HOTEL)
      .orderBy("bookingDate", "desc")
      .limit(1)
      .get();
    const booking = currentBookings.docs[0]?.data();
    const currentPeriodOffer = await db
      .collection(COLLECTION_HOTEL28355)
      .doc(doc.hotelId)
      .collection(COLLECTION_EXCLUSIVEHOTEL64774)
      .doc(doc.exclusiveOfferId)
      .get();
    if (!currentPeriodOffer.exists) {
      throw new Error("The exclusive offer does not exist");
    }
    const periodOffer = currentPeriodOffer.data();

    const currentHotel = await db
      .collection(COLLECTION_HOTEL28355)
      .doc(doc.hotelId)
      .get();
    if (!currentHotel.exists) {
      throw new Error("The exclusive offer does not exist");
    }
    const hotel = currentHotel.data();

    return db.collection(COLLECTION_BOOKING30512_HOTEL).add({
      ...doc,
      bookingDate: firebase.firestore.FieldValue.serverTimestamp(),
      exclusiveOffer: periodOffer,
      hotel,
      status: "NEW",
      comment: "",
      bookingId: booking ? +(booking?.bookingId || 0) + 1 : 1,
      // createBy: user,
    });
  } catch (err) {
    throw err;
  }
}

export function updateBooking30512HotelInFirestore(
  docId: string,
  doc: IHotelBookingInput | any,
) {
  console.log(doc);
  return db
    .collection(COLLECTION_BOOKING30512_HOTEL)
    .doc(docId)
    .update({
      ...doc,
    });
}
// export const addBooking30512HotelToFirestore = httpsCallable<
//   IHotelBookingInput,
//   IHotelBookingResponse
// >(functions, "bookingFunctions-hotelInitBookingToFirestore");

export function activeToggleBooking30512HotelInFirestore(
  docId: string,
  status: boolean,
) {
  return db.collection(COLLECTION_BOOKING30512_HOTEL).doc(docId).update({
    status,
  });
}

export function deleteBooking30512HotelInFirestore(docId: string) {
  return db.collection(COLLECTION_BOOKING30512_HOTEL).doc(docId).delete();
}
