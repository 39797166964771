import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_CATEGORY81497 } from "./firebaseConstants";

export function fetchCategory81497FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_CATEGORY81497);
  return collectionWithArgs(ref, args || []);
}
export function listenToCategory81497FromFirestore(id: string) {
  return db.collection(COLLECTION_CATEGORY81497).doc(id);
}
