import styled from "styled-components";
export const ThingsToDohWrapper = styled.div`
  #viatorWidget {
    width: auto !important;
    .widget_header {
      display: none;
    }
    .widget_body {
      background-color: #ffffff !important;
      font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    }
    .widget_entry {
      padding: 5px 0 20px;
      display: block;
      min-height: 155px;
      border-bottom: 1px solid #eee;
      margin-bottom: 10px;
      .widget_product_title {
        color: #2682a0;
        font-size: 13px;
      }
      .price_format {
        margin-bottom: 5px;
        .price {
          float: left;
          font-weight: bold;
          font-size: 14px;
          padding-right: 5px;
          color: #00a3d9;
        }
      }
      ul {
        font-size: 11px;
        list-style: none;
        margin: 0;
        padding: 0 7px 0;
        float: left;
        width: 66%;
        .widget_more_info {
          float: right;
          color: #00759a;
        }
      }
    }
  }

  .ViatorFindTTD {
    width: 500px !important;
    margin: auto;
    @media (max-width: 500px) {
      width: 100% !important;
    }
    > .pas {
      background-color: rgba(15, 91, 153, 0.6) !important;
      padding: 35px 20px !important;
      border-radius: 0 !important;
      border: none !important;
      .module_header {
        display: none;
      }
      .module_body {
        background-color: transparent !important;
        font-family: $font-family-sans-serif-title !important;
        .pas_destinations {
          position: relative;
          margin-bottom: 20px;
          background-color: #fff;
          padding: 7px 20px 3px;
          box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.2);
          #pascountry {
            border-bottom: 1px solid #a7a7a7;
          }
        }
        .pas_search_option {
          display: flex;
          align-items: center;
          line-height: 21px;
          gap: 20px;
          .checkbox {
            margin-bottom: 0;
          }
        }
        .pas_activities {
          .dd {
            display: inline;
          }
        }
        .btn {
          float: none !important;
          box-shadow: none;
          background-color: transparent !important;
          width: 100%;
          padding: 0;
          button {
            height: auto;
            border-radius: 3px;
            background: transparent;
            text-decoration: none;
            padding: 15px 12px;
            font-size: 14px;
            line-height: 21px;
            width: 100%;
            border: 1px solid #8db906;
            background-color: #8db906;
            color: #fff !important;
            margin: 6px 0px;
            transition: all 0.4s ease-in-out;
            box-shadow:
              0 2px 5px 0 rgba(0, 0, 0, 0.16),
              0 2px 10px 0 rgba(0, 0, 0, 0.12);
            &:hover {
              opacity: 1;
              background-color: darken(#8db906, 10%);
              border-color: darken(#8db906, 10%);
              transition: all 0.4s ease-in-out;
            }
          }
          &:hover {
            box-shadow: none;
            background-color: transparent !important;
          }
        }
      }
      select,
      input {
        margin: 0;
        border-bottom: none;
        height: 40px;
        color: #212121;
        margin-bottom: 10px;
        //min-width: 200px;
        display: block;
        background-color: #fff !important;
        -webkit-appearance: menulist !important;
        border-radius: 0 !important;
      }
      label {
        &:before {
          border: 2px solid #ffffff;
        }
      }
      [type="checkbox"]:checked + label:before {
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
      }
    }
  }
`;
