import { Button } from "antd";
import React from "react";
import { Space } from "../../atoms/Space/Space";
import { BookingWrapper } from "./Booking.style";

interface IProps {
  onSubmit: (type: string) => () => void;
  partialPayment: number;
  totalPayment: number;
  onRequest: boolean;
  loading: boolean;
  isTabletOrMobile: boolean;
}
export const BookingHotelPaymentInput: React.FC<IProps> = ({
  onSubmit,
  partialPayment,
  totalPayment,
  onRequest,
  loading,
  isTabletOrMobile,
}) => (
  <BookingWrapper>
    <div className="booking-container">
      <div className="booking-title">
        {onRequest
          ? "Προχωρήστε σε Αίτημα Κράτησης"
          : "Επιλέξτε το Ποσόν Πληρωμής για τη Κράτηση σας"}
      </div>
      {!onRequest && (
        <Space
          direction={!isTabletOrMobile ? "horizontal" : "vertical"}
          style={{ width: "100%" }}
        >
          <div>
            <div className="mealPriceSelection-include">
              Προτιμώ να πληρώσω για ολόκληρη τη κράτηση μου αξίας{" "}
              <span style={{ fontWeight: 700, fontSize: 18 }}>
                €{totalPayment}
              </span>{" "}
              από τώρα.
            </div>
            <Button
              className="booking-btn"
              type="primary"
              onClick={onSubmit("FULL_PAYMENT")}
              loading={loading}
            >
              Πλήρης Πληρωμή
            </Button>
          </div>
          <div>
            <div className="mealPriceSelection-include">
              Κρατήστε σήμερα το δωμάτιο σας και πληρώστε μόνο
              <span style={{ fontWeight: 700, fontSize: 18 }}>
                €{partialPayment}
              </span>
            </div>
            <Button
              className="booking-btn"
              type="primary"
              onClick={onSubmit("PARIETAL_PAYMENT")}
              loading={loading}
            >
              Προχώρα σε Μερική Πληρωμή
            </Button>
          </div>
        </Space>
      )}
      {onRequest && (
        <Button
          className="booking-btn"
          onClick={onSubmit("ON_REQUEST")}
          loading={loading}
        >
          Αίτημα κράτησης
        </Button>
      )}
    </div>
  </BookingWrapper>
);
