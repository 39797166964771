/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import cuid from "cuid";
import { useNavigate } from "react-router-dom";
import { find, orderBy } from "lodash";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { Alert, Button, Form } from "../../atoms";
import { SelectField } from "../commonFields";
import { Space } from "../../atoms/Space/Space";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchExclusiveHotel64774FromFirestore } from "../../../firestore/firestoreService";
import { InputDateRangeField } from "../commonFields/InputDateRangeField";
import { IExclusiveHotel64774 } from "../../../common/types";
import { splitDateRangeIntoPeriods } from "../../../common/util/util";
import { HotelSearchAvailabilityWrapper } from "./Hotel.style";

interface IProps {
  hotelId: string;
  exclusiveOfferIdParam?: string;
  startDate?: string;
  adultNumbers: number;
  childrenNumber: number;
  infant: number;
}
const maxNumber = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];
export const HotelSearchAvailability: React.FC<IProps> = ({
  hotelId,
  exclusiveOfferIdParam,
  startDate,
  adultNumbers,
  childrenNumber,
  infant,
}) => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [exclusiveOffers, setExclusiveOffers] = React.useState<
    IExclusiveHotel64774[]
  >([]);
  const [duration, setDuration] = React.useState("");
  const [dateOptions, setDateOptions] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  useFirestoreCollection({
    query: () =>
      fetchExclusiveHotel64774FromFirestore(hotelId, [
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: true,
        },
      ]),
    data: (data: any) =>
      setExclusiveOffers(
        data?.filter(
          (v: IExclusiveHotel64774) =>
            !moment(v.periodEndDate).isBefore(moment()),
        ),
      ),
    deps: [dispatch, hotelId],
    local: true,
    shouldExecute: !!hotelId,
  });

  const handleSubmit = async () => {
    try {
      const values: any = await form.validateFields();
      setLoading(true);
      const prams = new URLSearchParams({
        exclusiveOfferId: values.exclusiveOfferId,
        adultNumbers: values.adultNumbers,
        childrenNumber: values.childrenNumber,
        roomNumber: values.roomNumber,
        startDate: values.duration,
        checkInCheckOut:
          find(dateOptions, ["value", values.duration])?.label || "",
        infant: values.infant,
        reCall: cuid(),
      });

      history(`/hotel/${hotelId}?${prams.toString()}`);
      // getExclusiveOfferId(values.exclusiveOfferId);
      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const handlePeriodChange = (periodId: string) => {
    const period = find(exclusiveOffers, ["id", periodId]);
    if (period) {
      setDuration(period.duration);
      if (+period.duration > 1) {
        const dateOptionSelected = splitDateRangeIntoPeriods(
          period.periodStartDate,
          period.periodEndDate,
          +period.duration,
        );
        setDateOptions(dateOptionSelected);
        form.setFieldValue("duration", undefined);
      }
    }
  };
  React.useEffect(() => {
    if (exclusiveOfferIdParam) {
      console.log(exclusiveOfferIdParam);
      handlePeriodChange(exclusiveOfferIdParam);
      form.resetFields();
    }
  }, [exclusiveOfferIdParam, exclusiveOffers]);
  // React.useEffect(() => form.resetFields, [dateOptions]);
  return (
    <HotelSearchAvailabilityWrapper>
      <Form
        form={form}
        name="hotelRoomSearch"
        layout="vertical"
        autoComplete="off"
        initialValues={{
          adultNumbers,
          childrenNumber,
          roomNumber: 1,
          infant,
          exclusiveOfferId: exclusiveOfferIdParam,
          duration: startDate,
        }}
      >
        <div className="hotelSearchAvailability-fields">
          <SelectField
            label="Επιλέξτε Περίοδο"
            placeholder="Period"
            name="exclusiveOfferId"
            onChange={handlePeriodChange}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
            style={{ width: "100%" }}
            options={
              orderBy(exclusiveOffers, ["periodStartDate"])?.map((v: any) => ({
                value: v.id,
                label: v.name,
              })) || []
            }
          />
          {duration && +duration > 1 && (
            <SelectField
              label={
                <div>
                  <CalendarOutlined /> Ημερομηνίες
                </div>
              }
              placeholder="Επιλέξτε Ημερομηνίες"
              name="duration"
              help={
                <span style={{ color: "red" }}>
                  {duration} Βράδια Ελάχιστη Διαμονή
                </span>
              }
              options={dateOptions}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            />
          )}
          {duration && +duration === 1 && (
            <InputDateRangeField
              label="Ημερομηνίες"
              placeholder="Ημερομηνίες"
              name="checkInCheckOut"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            />
          )}
          <Space style={{ width: "100%", alignItems: "flex-start" }}>
            <SelectField
              label="Δωμάτια"
              name="roomNumber"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              style={{ width: "100%" }}
              options={maxNumber.filter((v) => +v.value === 1)}
            />
            <SelectField
              label="Ενήλικες 12+"
              name="adultNumbers"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              style={{ width: "100%" }}
              options={maxNumber.filter((v) => v.value > 0)}
            />
            <SelectField
              label="Παιδιά 2-11 ετών"
              name="childrenNumber"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              style={{ width: "100%" }}
              options={maxNumber}
            />
            <SelectField
              label="Βρέφη 0-23 μηνών"
              name="infant"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              style={{ width: "100%" }}
              options={maxNumber.filter((v) => v.value < 2)}
            />
          </Space>

          <div className="hotelSearchAvailability-actions">
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
              style={{ width: "100%" }}
            >
              Έλεγχος Διαθεσιμότητας
            </Button>
          </div>
        </div>
      </Form>
      {error !== "" && <Alert message={error} type="error" />}
    </HotelSearchAvailabilityWrapper>
  );
};
