import React from "react";
import { Form, Input } from "../../atoms";

interface IInputFieldProps {
  label: string | React.ReactElement | React.ReactNode;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  prefix?: string | React.ReactElement;
  disabled?: boolean;
  hidden?: boolean;
  normalize?: any;
  onPressEnter?: () => void;
  onChange?: (value: any) => void;
  noStyle?: boolean;
  help?: string | React.ReactElement;
}

export const InputField: React.FC<IInputFieldProps> = ({
  label,
  rules,
  name,
  placeholder,
  style,
  className,
  initialValue,
  bordered = true,
  prefix,
  disabled,
  hidden,
  normalize,
  onPressEnter,
  onChange,
  noStyle,
  help,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
    hidden={hidden}
    normalize={normalize}
    noStyle={noStyle}
    help={help}
  >
    <Input
      placeholder={placeholder}
      bordered={bordered}
      prefix={prefix}
      disabled={disabled}
      onPressEnter={onPressEnter}
      onChange={onChange}
    />
  </Form.Item>
);
