import React from "react";
import { Form, Select } from "../../atoms";
interface ISelectOption {
  value: string | number;
  label: string | React.ReactElement;
}
interface ISelectFieldProps {
  options: ISelectOption[];
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  onSelect?: (event: any) => void;
  onChange?: (event: any) => void;
  bordered?: boolean;
  disabled?: boolean;
  showSearch?: boolean;
  help?: string | React.ReactNode;
}

export const SelectField: React.FC<ISelectFieldProps> = ({
  options,
  label,
  rules,
  name,
  placeholder,
  style,
  mode,
  maxTagCount,
  initialValue,
  onSelect,
  onChange,
  bordered = true,
  disabled,
  showSearch = true,
  help,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    initialValue={initialValue}
    style={style}
    help={help}
  >
    <Select
      showSearch={showSearch}
      bordered={bordered}
      style={style}
      disabled={disabled}
      placeholder={placeholder || label}
      optionFilterProp="label"
      filterOption={(input, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={options}
      mode={mode}
      maxTagCount={maxTagCount}
      onSelect={onSelect}
      onChange={onChange}
    ></Select>
  </Form.Item>
);
