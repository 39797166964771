/* eslint-disable no-console */
import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import {
  IFlight,
  ILuggage,
  IPackageHotelPublic,
  IRoomInput,
} from "../../../common/typings";
import { formatReadableAddress, getRoomCost } from "../../../common/util/util";
import { CartItem } from "../CartItem/CartItem";
import { HotelInfo } from "../Hotel/HotelInfo";
import { CartLuggageItem } from "../CartItem/CartLuggageItem";
import { PackageFlights } from "../Packages";
import { CartWrapper } from "./Cart.style";
import { CartTotalBtn } from "./CartTotalBtn";

const roomTypes: { [key: string]: number } = {
  single: 1,
  double: 2,
  triple: 3,
  quadruple: 4,
};

export interface ICartProp {
  hotels: IPackageHotelPublic[];
  rooms: IRoomInput[];
  submit?: () => void;
  loading: boolean;
  isTabletOrMobile: boolean;
  onShowCart?: () => void;
  flights: IFlight[];
  luggages: ILuggage;
  noScrolling?: boolean;
  discount: number;
}
export const Cart: React.FC<ICartProp> = ({
  hotels,
  rooms,
  submit,
  loading,
  isTabletOrMobile,
  onShowCart,
  luggages,
  flights,
  noScrolling,
  discount,
}) => (
  <CartWrapper style={{ height: noScrolling ? "auto" : "100%" }}>
    <div className="cart-header">
      <div className="cart-title">
        <FormattedMessage
          description="Your Package Details"
          defaultMessage="Your Package Details"
          id="vCnSB4"
        />
      </div>
      {isTabletOrMobile && submit && (
        <div className="cart-close">
          <CloseCircleOutlined onClick={onShowCart} />
        </div>
      )}
    </div>

    <div
      className="cart-hotel"
      style={{ overflow: noScrolling ? "unset" : "hidden" }}
    >
      <div className="cart-passengers">
        <div>
          <div className="cart-sub-title">
            <FormattedMessage
              description="Your Flights"
              defaultMessage="Your Flights"
              id="JYuDhN"
            />
          </div>
          <PackageFlights flights={flights} isMobile noHeader />
        </div>

        <div className="cart-sub-title">
          {" "}
          <FormattedMessage
            description="Your Hotel(s)"
            defaultMessage="Your Hotel(s)"
            id="08YBY7"
          />
        </div>
        {hotels.map((packageHotel) => (
          <div key={packageHotel.hotel?.id || ""}>
            <HotelInfo
              starRating={packageHotel.hotel?.starRating || 0}
              hotelName={packageHotel.hotel?.name}
              address={formatReadableAddress(packageHotel.hotel?.address) || ""}
              imageUrl={packageHotel.hotel?.defaultImage?.url || ""}
              mealType={packageHotel.mealsType}
            />
            <div className="cart-body">
              {rooms.map((room, index) => (
                <CartItem
                  title={`1x ${room.roomType} room`}
                  adults={roomTypes[room.roomType]}
                  child={room.numberChilds || 0}
                  info={packageHotel.mealsType}
                  price={getRoomCost(room, packageHotel, discount)}
                  currency="€"
                  key={index}
                />
              ))}
            </div>
          </div>
        ))}
        {(luggages.numberOf10KGLuggage > 0 ||
          luggages.numberOf20KGLuggage > 0) && (
          <div style={{ marginTop: 20 }}>
            <div className="cart-sub-title"> Αποσκευές</div>
            <div className="cart-body">
              {luggages.numberOf10KGLuggage > 0 &&
                luggages.price10KGLuggage > 0 && (
                  <CartLuggageItem
                    title={`${luggages.numberOf10KGLuggage}x Χειραποσκευή 10 κιλών`}
                    info={""}
                    price={
                      Number(luggages.numberOf10KGLuggage) *
                      Number(luggages.price10KGLuggage)
                    }
                    currency="€"
                  />
                )}
              {luggages.numberOf20KGLuggage > 0 &&
                luggages.price20KGLuggage > 0 && (
                  <CartLuggageItem
                    title={`${luggages.numberOf20KGLuggage}x Αποσκευή 20 κιλών`}
                    info={""}
                    price={
                      Number(luggages.numberOf20KGLuggage) *
                      Number(luggages.price20KGLuggage)
                    }
                    currency="€"
                  />
                )}
            </div>
          </div>
        )}
      </div>
    </div>
    {submit && (
      <div className="cart-footer">
        <CartTotalBtn
          submit={submit}
          hotels={hotels}
          rooms={rooms}
          loading={loading}
          luggages={luggages}
          discount={discount}
        />
      </div>
    )}
  </CartWrapper>
);
