import { FilterValue } from "antd/lib/table/interface";
import { IAmenity2092 } from "../../../common/types";
import {
  UPDATE_AMENITY2092,
  DELETE_AMENITY2092,
  FETCH_AMENITY2092,
  LISTEN_TO_SELECTED_AMENITY2092,
  FILTER_AMENITY2092,
} from "./amenity2092Constants";

export function listenToAmenity2092(amenity2092: IAmenity2092[]) {
  return {
    type: FETCH_AMENITY2092,
    payload: amenity2092,
  };
}

export function listenToSelectedAmenity2092(amenity2092: IAmenity2092) {
  return {
    type: LISTEN_TO_SELECTED_AMENITY2092,
    payload: amenity2092,
  };
}

export function updateAmenity2092(amenity2092: IAmenity2092) {
  return {
    type: UPDATE_AMENITY2092,
    payload: amenity2092,
  };
}

export function deleteAmenity2092(amenity2092Id: string) {
  return {
    type: DELETE_AMENITY2092,
    payload: amenity2092Id,
  };
}

export function listenToAmenity2092Filter(
  filters: Record<string, FilterValue | null>,
) {
  return {
    type: FILTER_AMENITY2092,
    payload: filters,
  };
}
