import React from "react";
import { Col, Row, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import moment from "moment";
import { InputDateField, InputField, SelectField } from "..";
import { IHotelBookingPhysicalRoom, IRoom40231 } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToRoom40231FromFirestore } from "../../../firestore/firestoreService";
import { BookingWrapper } from "./Booking.style";
function createArrayFromNumber(n: number) {
  return Array.from({ length: n }, (_, index) => index);
}
const partnerNameGr = process.env.REACT_APP_PARTNER_NAME_GR;
const cancelationPolicy: any = {
  REFUNDABLE: "Με δωρεάν ακύρωση μέχρι",
  NON_REFUNDABLE: "Χωρίς δωρεάν ακύρωση",
};
interface IProps {
  title: string;
  room: IHotelBookingPhysicalRoom;
  showDateOfBirth: boolean;
  hotelId: string;
}
export const BookingRoomInput: React.FC<IProps> = ({
  room,
  hotelId,
  showDateOfBirth,
}) => {
  const dispatch = useDispatch();
  const [roomDetails, setRoom] = React.useState<IRoom40231>();
  userFirestoreDoc({
    query: () => listenToRoom40231FromFirestore(hotelId, room.roomId || ""),
    data: setRoom,
    shouldExecute: !!room.roomId && !!hotelId,
    deps: [dispatch, room.roomId],
    local: true,
  });
  const createGuestIcons = (guestNumber: number) =>
    createArrayFromNumber(guestNumber).map((i: number) => (
      <UserOutlined key={i} />
    ));
  return (
    <BookingWrapper>
      <div className="booking-container">
        <div className="booking-titleRoom">
          <Space>
            <span className="HotelRoomCartItem-roomNumber">
              1x {roomDetails?.name || ""}
            </span>
            <div className="HotelRoomCartItem-guest">
              {createGuestIcons(room.adultNumber)}
            </div>
            <span className="HotelRoomCartItem-guest-child">
              {createGuestIcons(room.childNumber)}
              {+room.infantNumber === 1 && (
                <img
                  src="/assets/baby-boy.png"
                  alt=""
                  style={{
                    height: 12,
                    marginLeft: room.childNumber > 0 ? 5 : 0,
                  }}
                />
              )}
            </span>
            <div className="booking-policy">
              {cancelationPolicy[room?.cancelationPolicy || ""] || ""}{" "}
              {room.cancelationPolicy
                ? `(${moment(room.refundableDate).format("DD/MM/YYYY")})`
                : ""}
            </div>
          </Space>
        </div>
        <div className="booking-title">
          Στοιχεία Επισκέπτη (μέλος {partnerNameGr})
        </div>
        <Row gutter={24}>
          <Col span={8} xs={24} sm={12}>
            <SelectField
              label="Τίτλος"
              name={["roomsMainGuest", room.id, "title"]}
              rules={[
                {
                  required: true,
                  message: "Τίτλος",
                },
              ]}
              placeholder="Τίτλος"
              options={[
                { value: "MR", label: "Κος" },
                { value: "MS", label: "Κα" },
              ]}
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Όνομα"
              name={["roomsMainGuest", room.id, "name"]}
              rules={[
                {
                  required: true,
                  message: "Όνομα",
                },
              ]}
              placeholder="Όνομα"
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Επίθετο"
              name={["roomsMainGuest", room.id, "surname"]}
              rules={[
                {
                  required: true,
                  message: "Όνομα",
                },
              ]}
              placeholder="Επίθετο"
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputField
              label="Αριθμός Ταυτότητας"
              name={["roomsMainGuest", room.id, "idNumber"]}
              rules={[
                {
                  required: true,
                  message: "Αριθμός Ταυτότητας",
                },
              ]}
              placeholder="Αριθμός Ταυτότητας"
            />
          </Col>
          <Col span={8} xs={24} sm={12}>
            <InputDateField
              label="Ημ. Γεννήσεως"
              name={["roomsMainGuest", room.id, "dateOfBirth"]}
              rules={[
                {
                  required: true,
                  message: "Ημ. Γεννήσεως",
                },
              ]}
              style={{ width: "100%" }}
              placeholder="Ημ. Γεννήσεως"
            />
          </Col>
        </Row>
      </div>
    </BookingWrapper>
  );
};
