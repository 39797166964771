import classNames from "classnames";
import React from "react";
import { ISelectOption } from "../../../common/types";
import { Form, Radio } from "../../atoms";
interface IInputRadioFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  options: ISelectOption[];
  disabled?: boolean;
  optionType?: any;
  onChange?: (event: any) => void;
  vertical?: boolean;
  onClick?: (event: any) => void;
}

export const InputRadioField: React.FC<IInputRadioFieldProps> = ({
  label,
  rules,
  name,
  style,
  className,
  initialValue,
  bordered = true,
  options,
  disabled,
  onChange,
  optionType,
  vertical,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
  >
    <Radio.Group
      options={options}
      onChange={onChange}
      disabled={disabled}
      className={classNames({ vertical })}
      optionType={optionType}
      style={
        vertical ? { display: "flex", flexDirection: "column", gap: 15 } : {}
      }
    />
  </Form.Item>
);
