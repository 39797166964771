import { Rate } from "antd";
import React, { ReactElement } from "react";
import { HotelInfoWrapper } from "./Hotel.style";
export interface IHotelInfoProps {
  starRating: number;
  hotelName: string | ReactElement;
  address: string;
  imageUrl?: string;
  mealType: string;
  moreDetails?: () => void;
}
export const HotelInfo: React.FC<IHotelInfoProps> = ({
  starRating,
  hotelName,
  address,
  imageUrl,
  mealType,
  moreDetails,
}) => (
  <HotelInfoWrapper>
    {imageUrl && (
      <div className="hotel-info-img">
        <img src={imageUrl} alt="" />
      </div>
    )}
    <div className="hotel-info-details">
      <span className="hotel-info-name">{hotelName} </span>
      <Rate className="hotel-info-rate" disabled defaultValue={starRating} />
      <span className="hotel-info-location">{address}</span>
    </div>
  </HotelInfoWrapper>
);
