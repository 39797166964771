/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Many, flatten, map, uniq } from "lodash";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import {
  fetchCategory81497FromFirestore,
  fetchCity83431FromFirestore,
  fetchHotel28355FromFirestore,
  fetchPackagesFromFirestore,
} from "../../../firestore/firestoreService";
import { listenToPackages } from "../../../redux/data/package/packageActions";
import { ICategory, ICity, IPackage } from "../../../common/typings";
import {
  ItemNotFound,
  Loader,
  PackageItemList,
  PackageListFrontEndWrapper,
} from "../../molecules";
import { listenToCategories } from "../../../redux/data/category/categoryActions";
import { listenToHotels } from "../../../redux/data/hotel/hotelActions";
import PackageFilter from "../../molecules/Packages/PackageFilter";
import { listenToCities } from "../../../redux/data/city/cityActions";

const defaultSorting: any = {
  type: "sorting",
  name: "minPricePerAdult",
  order: "asc",
  opr: "asc",
};

export const PackageList: React.FC<{
  params: any;
  partner?: string;
}> = ({ params, partner }) => {
  const { packages } = useSelector((state: any) => state.packages);
  const { cities } = useSelector((state: any) => state.cities);
  const { categories } = useSelector((state: any) => state.categories);

  const { hotels } = useSelector((state: any) => state.hotels);
  const { loading, error } = useSelector((state: any) => state.async);
  const [showFilter, setShowFilters] = useState(true);
  const [packageCategoriesCode, setPackageCategoriesCode] = useState<
    ICategory[]
  >([]);
  const [packageCites, setPackageCites] = useState<ICity[]>([]);
  const [sorting, setSorting] = useState<any>(defaultSorting);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchPackagesFromFirestore([
        {
          type: "filter",
          name: "status",
          opr: "==",
          value: true,
        },
        (partner && {
          type: "filter",
          name: "partnerId",
          opr: "array-contains",
          value: partner,
        }) ||
          null,
        // ...convertParamsToQueryConfig(params),
      ]),
    data: (data: any) =>
      partner
        ? listenToPackages(data)
        : listenToPackages(
            data.filter((p: IPackage) => p.onlyPartner !== true),
          ),
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCategory81497FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCategories,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchHotel28355FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
        {
          type: "filter",
          name: "topHotel",
          opr: "==",
          value: true,
        },
      ]),
    data: listenToHotels,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCity83431FromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  const handelShowFilter = () => {
    setShowFilters((v) => !v);
  };
  const handelSortingChange = (value: string) => {
    const [name, direction]: [string, Many<boolean | "asc" | "desc">] =
      Object.values(value.split("-")) as [
        string,
        Many<boolean | "asc" | "desc">,
      ];
    setSorting({ type: "sorting", name, opr: "", order: direction });
  };
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  }, [isTabletOrMobile]);
  React.useEffect(() => {
    if (packages) {
      const packCat: string[] =
        uniq(flatten(map(packages, (item) => flatten(item.categoryCodes)))) ||
        [];
      const packCities: string[] =
        uniq(
          flatten(map(packages, (item) => flatten(item.destinationCodes))),
        ) || [];
      const selectCat = categories.filter((c: any) => packCat.includes(c.code));
      const selectCities = cities.filter((c: any) =>
        packCities.includes(c.code),
      );
      setPackageCites(selectCities);
      setPackageCategoriesCode(selectCat);
    }
  }, [packages, categories, cities]);
  console.log(packageCites);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to Home Page"
        itemType="City"
        itemId={"sd"}
      />
    );
  }
  // console.log(packages);
  return (
    <PackageListFrontEndWrapper>
      <div className="package-list-frontend">
        <div className="package-list-frontend-body">
          <div
            className={classNames("package-list-frontend-left", {
              hide: showFilter,
            })}
          >
            <PackageFilter
              cities={packageCites}
              categories={packageCategoriesCode}
              isTabletOrMobile={isTabletOrMobile}
              showFilter={handelShowFilter}
              params={params}
              hotels={hotels}
              url={"packages"}
            />
          </div>

          <PackageItemList
            travelPackages={packages}
            sorting={sorting}
            isTabletOrMobile={isTabletOrMobile}
            isMobile={isMobile}
            onSort={handelSortingChange}
            onShowFilter={handelShowFilter}
            partner={partner}
          />
        </div>
      </div>
    </PackageListFrontEndWrapper>
  );
};
